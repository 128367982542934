/* Hide the input component,
 * see https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
 */
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.uploadArea_box {
  border: 1px dashed var(--ion-color-primary-grey200);
  border-radius: 14px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  background-color: var(--light-blue100);
  height: 200px;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  border: 2px dashed var(--ion-color-primary);
}
