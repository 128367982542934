/* ORYX App 2.0
*
* Created by ORYX Movement Solutions © 2022
* ==================================================================
 * 
 * Homepage css.
 */

.login ion-grid {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
}

.login ion-row {
    justify-content: center;
  }

.login-col_left {
    display: flex;
    background-color: #70707025;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: space-around;
    height: 98vh;
}

.intro_bg {
    width: calc(100% - 86px);
    max-width: 600px;
    position: absolute;
    opacity: 0.75;
    margin-top: 20px;
}

.intro_image {
    position: absolute;
    width: calc(100% - 86px);
    max-width: 600px;
}

.login_form {
    max-width: 600px;
    height: 90vh;
  }

