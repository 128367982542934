.gatewayCard-root {
  background-color: white;
  width: 100%;
  /* height: 400px;
  display: inline-block; */
}
.gatewayCard-header {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.gatewayCard_Header_NotConnected {
  box-shadow: none !important;
  height: 60px;
  text-align: left;
  padding: 0 !important;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
  padding-left: 10px;
}

.connectButton {
  bottom: 0;
}

.gatewayCard-title {
  font-size: 17px;
  width: 80%;
  margin-right: 0px;
}
.gatewayCard-header-buttons {
  justify-content: flex-end;
  float: right;
  top: -25px;
  position: relative;
}
/* Buttons */
.gatewayCard-header-info {
  font-size: 22px;
  color: black;
}
.gatewayCard-Modal-close {
  height: 80px;
  font-size: 58px;
  /* position: fixed;
  bottom: 10px; */
  align-self: center;
  color: #4d4f5c40;
  --ionicon-stroke-width: 16px;
}

/* Modal section */
.gatewayCard-modal .modal-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  width: 450px;
  height: 600px;
}

.gatewayCard-modal-header {
  height: 72px;
  color: #2066fa;
  font-size: 24px;
  line-height: 60px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.gatewayCard-modal-content {
  position: relative;
  max-height: 100%;
}
.gatewayCard-modal-List {
  position: relative;
  max-height: 100%;
}
.test {
  border: 1px solid purple;
}

.gatewayCard-modal-listItem {
  margin: 12px 20px;
  border-radius: 4px;
  border: 1px solid #e4e7e8;
}

ion-toggle {
  --background-checked: #2066fa;
}
