.content_Row {
  justify-content: center;
  width: 100%;
  height: 65vh;
}

.sensorColumn_Left {
  /* border-right: 1px solid #e4e7e8; */
}

.mainColumn {
  padding-left: 10%;
  padding-right: 10%;
}

.sensorColumn_Right {
  /* border-left: 1px solid #e4e7e8; */
}
.gatewayCard-content-List {
  width: 100%;
}

.gatewayCard-content-listItem {
  margin: 12px 0px;
  border-radius: 4px;
  border: 1px solid #e4e7e8;
}

.mainColumn_Row {
  margin: 30% 0;
}

.oryx_button {
  width: 95%;
  --background: linear-gradient(#2066fa, #1649d8);
  --border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  text-transform: none;
}

.oryx_button_small {
  width: 33%;
  --background: linear-gradient(#2066fa, #1649d8);
  --border-radius: 10px;
  text-transform: none;
}

.scannedSensor_Row {
  height: 40%;
}

.calibration_Row {
  height: 90%;
  width: 100%;
}

.recording_Row {
  height: 90%;
  width: 100%;
}
.last_row {
  margin-top: 10px;
}
.one_row {
  align-items: flex-end;
  margin-top: 55vh;
}
.left {
  height: 100%;
  padding: 5px;
}
.right {
  height: 100%;
  padding: 5px;
}

.three_Rows {
  height: 33%;
}
.three_Rows:first-child {
  top: 0;
  height: 33%;
}

.state_Title {
  height: 10%;
  top: 0;
}

.title_Row {
  height: 10%;
}

.oryx_progressbar {
  height: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  --progress-background: repeating-linear-gradient(45deg, #174bda, #2066fa 20px);
  --background: repeating-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.25));
  border-radius: 4px;
}

.content_center_col {
  display: flex;
}

.timertekst {
  color: #4d4f5c;
  font-style: normal;
  font-weight: bold;
  font-size: xx-large;
  text-align: center;
}
