/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #2066fa;
  --ion-color-primary-rgb: 32, 102, 250;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0546d1;
  --ion-color-primary-tint: #6394fb;

  /** secondary **/
  --ion-color-secondary: #baff6b;
  --ion-color-secondary-rgb: 186, 255, 107;
  --ion-color-secondary-contrast: #707070;
  --ion-color-secondary-contrast-rgb: 112, 112, 112;
  --ion-color-secondary-shade: #9aff27;
  --ion-color-secondary-tint: #daffaf;

  /** tertiary **/
  --ion-color-tertiary: #e050a0;
  --ion-color-tertiary-rgb: 224, 80, 160;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #c8247f;
  --ion-color-tertiary-tint: #ea8abf;

  /** success **/
  --ion-color-success: #22c55e;
  --ion-color-success-rgb: 34, 197, 94;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #15803d;
  --ion-color-success-tint: #86efac;

  /** warning **/
  --ion-color-warning: #f97316;
  --ion-color-warning-rgb: 249, 115, 22;
  --ion-color-warning-contrast: #707070;
  --ion-color-warning-contrast-rgb: 112, 112, 112;
  --ion-color-warning-shade: #c23d0c;
  --ion-color-warning-tint: #fdba74;

  /** danger **/
  --ion-color-danger: #eb3737;
  --ion-color-danger-rgb: 235, 55, 55;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c21313;
  --ion-color-danger-tint: #fca5a5;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** Background color **/
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-text-color: #707070;
  --ion-text-color-rgb: 112, 112, 112;

  --ion-color-step-50: #eaeaf0;
  --ion-color-step-100: #e3e3ea;
  --ion-color-step-150: #dddde3;
  --ion-color-step-200: #d6d6dc;
  --ion-color-step-250: #d0d0d5;
  --ion-color-step-300: #cacace;
  --ion-color-step-350: #c3c3c8;
  --ion-color-step-400: #bdbdc1;
  --ion-color-step-450: #b6b6ba;
  --ion-color-step-500: #b0b0b4;
  --ion-color-step-550: #aaaaad;
  --ion-color-step-600: #a3a3a6;
  --ion-color-step-650: #9d9d9f;
  --ion-color-step-700: #969699;
  --ion-color-step-750: #909092;
  --ion-color-step-800: #8a8a8b;
  --ion-color-step-850: #838384;
  --ion-color-step-900: #7d7d7e;
  --ion-color-step-950: #767677;

  --ion-toolbar-background: #ffffff;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #2066fa;
    --ion-color-primary-rgb: 32, 102, 250;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #0546d1;
    --ion-color-primary-tint: #6394fb;

    /** secondary **/
    --ion-color-secondary: #baff6b;
    --ion-color-secondary-rgb: 186, 255, 107;
    --ion-color-secondary-contrast: #707070;
    --ion-color-secondary-contrast-rgb: 112, 112, 112;
    --ion-color-secondary-shade: #9aff27;
    --ion-color-secondary-tint: #daffaf;

    /** tertiary **/
    --ion-color-tertiary: #e050a0;
    --ion-color-tertiary-rgb: 224, 80, 160;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #c8247f;
    --ion-color-tertiary-tint: #ea8abf;

    /** success **/
    --ion-color-success: #22c55e;
    --ion-color-success-rgb: 34, 197, 94;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #15803d;
    --ion-color-success-tint: #86efac;

    /** warning **/
    --ion-color-warning: #f97316;
    --ion-color-warning-rgb: 249, 115, 22;
    --ion-color-warning-contrast: #707070;
    --ion-color-warning-contrast-rgb: 112, 112, 112;
    --ion-color-warning-shade: #c23d0c;
    --ion-color-warning-tint: #fdba74;

    /** danger **/
    --ion-color-danger: #eb3737;
    --ion-color-danger-rgb: 235, 55, 55;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #c21313;
    --ion-color-danger-tint: #fca5a5;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18, 18, 18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}
