.primary {
  --height: 40px;
  --background: var(--blueGradient);
  --background-hover: var(--darkBlueGradient);
  --background-activated: var(--darkBlueGradient);

  --background-focused: var(--darkBlueGradient);
  --background-disabled: rgba(var(--grey100-rgb), 0.8);
  --border-radius: 30px;
  --color: #ffffff;

  --ripple-color: var(--primary900);

  --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);

  --padding-top: 10px;
  --padding-bottom: 10px;
  width: 275px;
}

.cardHeader_Button {
  position: absolute;
  top: 40%;
  right: 0;
  transform: translateY(-50%);
}

.primary_Button {
  height: 38px;
  background: var(--blueGradient);
  --background-disabled: rgba(var(--grey100-rgb), 0.8);
  border-radius: 30px;
  color: #ffffff;
  box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-size: 20px;
}

.success_Button {
  height: 38px;
  background: var(--greenGradient);
  --background-disabled: rgba(var(--grey100-rgb), 0.8);
  border-radius: 30px;
  color: var(--ion-text-color);
  box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-size: 20px;
}

.secondary {
  --height: 40px;
  --background: linear-gradient(180deg, rgba(32, 102, 250, 0.3), rgba(22, 73, 216, 0.3));
  --background-hover: var(--darkBlueGradient);
  --background-activated: var(--darkBlueGradient);

  --background-focused: var(--darkBlueGradient);
  --background-disabled: rgba(var(--grey100-rgb), 0.8);
  --border-radius: 30px;
  --color: #ffffff;

  --ripple-color: var(--primary900);

  --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);

  --padding-top: 10px;
  --padding-bottom: 10px;
}

.transparent {
  --height: 40px;
  --background: transparent;
  --background-hover: rgba(var(--primary-grey100-rgb), 0.25);
  --background-activated: rgba(var(--primary-grey100-rgb), 0.25);

  --background-focused: rgba(var(--primary-grey100-rgb), 0.25);
  /* --background-disabled: rgba(var(--primary-grey100-rgb), 0.8); */
  --color: var(--ion-text-color);
  --ripple-color: var(--primary-grey900);

  --padding-top: 10px;
  --padding-bottom: 10px;
  text-decoration: underline;
}

.errorIcon {
  --color: var(--ion-color-error600);
  --background: transparent;
  --height: 40px;
  --padding-top: 10px;
  --padding-bottom: 10px;
}
