ion-col > div {
  padding: 10px;
  margin: 10px;
  background-color: white;
  text-align: left;
  font-family: inherit;
  color: #737373;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
}

/* ===== Swiper ===== */
.swiper-container {
  overflow-y: auto;
}

.swiper-wrapper {
  width: 99%;
  height: 99%;
  margin: auto;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  border: none;
  @media only screen and (min-width: 1140px) {
    display: none;
  }
}

.swiper-pagination-bullet {
  width: 20px;
  height: 20px;
  right: 20px;
  border: 1px solid #dee2e7;
}

/* ======= */
.reportInfoModal_Header {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  height: 70px;
}

.reportInfoModal_Title {
  color: #2066fa;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}

.reportInfoModal_Subtitle {
  color: #2066fa;
  font-size: 16px;
  text-align: center;
}

.reportInfoModal_Avatar {
  height: 450px;
}

.reportInfoModal_Avatar_M {
  height: 325px !important;
}

.reportInfoModal_Avatar_S {
  height: 225px;
}

.reportInfoModal_Icon {
  color: #dee2e7;
}
.reportInfoModal_Caption {
  font-size: 10px;
}
