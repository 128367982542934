/* .ion-tab-button_selected {
  background-color: var(--ion-color-primary);
  color: #ffffff;
  border-radius: 20px;
  padding: 10px 0;
} */

.disabled {
  opacity: 0.2;
}
