.reportCard_Full {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.reportCard_Header {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  height: 70px;
}

.section {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0 !important;
}

.section-header {
  display: flex;
  justify-content: space-between;
  height: 70px;
}
.section-header_graph {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
}

.reportCard_Buttons {
  justify-content: flex-end;
  float: right;
  top: -25px;
  position: relative;
  color: black;
}

.reportCard_Infobutton {
  font-size: 22px;
}

.reportCard_Full_content p {
  margin-bottom: 1em;
}
.reportCard_Full_content {
  padding-top: 20px !important;
}

.reportCard_Full_content p:last-child {
  margin-bottom: 0;
}
.reportCard_Graph_content {
  margin-bottom: 0;
  padding-top: 20px !important;
}
.reportCard_Title {
  font-size: 17px;
  width: 80%;
  margin-right: 0px;
}
.reportCard_Subtitle {
  font-size: 10px;
  width: 80%;
  margin-right: 0px;
}

.section__modal .modal-wrapper {
  background-color: white;
  border-radius: 4px;
  width: 1000px;
  height: 650px;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
}

ion-modal {
  --ion-background-color: #ffffff;
}
