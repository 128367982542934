.ORYX_title {
  /* border: 1px solid green; */
  height: 48px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  text-align: center;
  background-color: #ffffff;
}

.remaining_height {
  height: 80vh;
}

.network_Card {
  /* width: 255px; */
  height: 290px;
}

.netwrok_Content {
  border: 1px solid black;
}

.network_Icon {
  height: 80px;
  font-size: 58px;
  /* position: fixed;
  bottom: 10px; */
  margin-left: auto;
  margin-right: auto;
  filter: invert(30%) sepia(12%) saturate(514%) hue-rotate(195deg) brightness(96%) contrast(91%);
  --ionicon-stroke-width: 16px;
  margin-bottom: 1rem;
}

.network_Title {
  text-align: center;
}

.animate {
  animation-duration: 0.3s;
  animation-name: animate-popin;
  animation-fill-mode: backwards;
}

@keyframes animate-popin {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }

  75% {
    transform: scale(1.02, 1.02);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
