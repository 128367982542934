.separator {
  color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
  padding-left: 10px;
  padding-right: 10px;
}

.status-dot {
  display: block;
  margin-left: 5px;
  right: 0;
  height: 20px;
  width: 20px;
  background-color: orange;
  border-radius: 50%;
}

.status-dot-centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  right: 0;
  height: 20px;
  width: 20px;
  background-color: grey;
  border-radius: 50%;
}

.speed-dot {
  display: block;
  margin-left: 5px;
  right: 0;
  height: 20px;
  width: 20px;
  background-color: orange;
  border-radius: 50%;
}
