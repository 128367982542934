.reportAvatar_Run {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 256px;
}

.reportAvatar_Run_Subtitle {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 244px;
}

.reportCard_Title {
  font-size: 17px;
  width: 75%;
  margin-right: 0px !important;
}

.reportCard_Title_XS {
  font-size: 14px;
  width: 85%;
  margin-right: 0px !important;
}

.reportCard_Title_XS_noSubtitle {
  font-size: 14px;
  width: 85%;
  margin-right: 0px !important;
  top: 8px;
}
.reportCard_Subtitle {
  font-size: 10px;
  width: 75%;
  margin-right: 0px !important;
}

.reportCard_Subtitle_XS {
  font-size: 8px;
  width: 75%;
  margin-right: 0px !important;
}

.section__modal .modal-wrapper {
  background-color: white;
  border-radius: 4px;
  width: 1000px;
  height: 650px;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
}

ion-modal {
  --ion-background-color: #ffffff;
}

.reportCard_Content {
  padding: 10px;
}
.reportCard_Content p {
  margin-bottom: 1em;
}

.reportCard_Content p:last-child {
  margin-bottom: 0;
}

.reportCard_Small_Buttons {
  float: right;
  color: black;
  height: 35px;
  position: relative;
  top: -32px;
}

.reportCard_Small_Buttons_Nosubtitle {
  float: right;
  color: black;
  height: 35px;
  position: relative;
  top: -18px;
}

.reportCard_Infobutton {
  font-size: 22px;
}
.reportCard_Small {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0 !important;
}
.reportCard_Small_Error {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0 !important;
  border: 1px solid var(--ion-color-danger);
}

.reportCard_Header {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  height: 70px;
}

.reportCard_FirstRow_Subtitle {
  top: -20px;
}

.reportCard_LastRow {
  bottom: -20px;
  border: 1px solid blue;
  position: relative;
}

.reportInfo_Modal_Close {
  height: 80px;
  font-size: 58px;
  /* position: fixed;
  bottom: 10px; */
  align-self: center;
  color: #4d4f5c40;
  --ionicon-stroke-width: 16px;
}
