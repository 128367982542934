.deviceManagement_Card {
  height: 550px;
}

.deviceManagement_Card_Header {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.deviceManagement_Card_Container {
  height: 400px;
}

.deviceManagement_Card_Segment {
  padding: 2px;
  height: 70px;
  bottom: 5px;
}

.md .segment-button {
  --background: white;
  --color: #4d4f5c;
  --border-color: var(--ion-color-primary);
  --background-checked: rgba(var(--ion-color-primary-rgb), 0.14);
  --color-checked: var(--ion-color-primary);
  --indicator-height: 5px;
  padding: 2px;
  margin: 0;
  border-radius: 0.5rem !important;
  text-transform: none;
}

.deviceManagement_Card_Segment_Separator {
  height: 100%;
  display: inline-block;
  height: inherit;
  border-left: 1px solid #4d4f5c40;
  margin: 0 10px;
}

.sensorCard_Content {
  padding: 10px !important;
}

.sensorCard_Image_Idle {
  height: 50px;
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  opacity: 0.4;
}

.sensorCard_Image {
  height: 50px;
}

.sensorCard_Connect_Toggle {
  float: right;
}

.sensorCard_Title {
  font-weight: bold;
}

.sensorCard_Subtitle {
  font-size: smaller;
}

.sensorInfo-modal-listItem {
  margin: 20px 20px;
  border-radius: 4px;
  border: 1px solid #e4e7e8;
}

.align_center {
  text-align: center;
}

.sensorInfo_Modal_Icon {
  font-size: 26px;
  line-height: 15px;
}
