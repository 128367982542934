/* ORYX App 2.0
*
* Created by ORYX Movement Solutions © 2022
* ==================================================================
 * 
 * Login form Component styling, including the logo
 */

.test {
  background-color: #eb445a;
  border-radius: 4px;
  text-align: center;
}
.login_logo {
  width: 75%;
  max-width: 300px;
  display: block;
  position: relative;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
.login ion-col > div {
  padding: 10px;
  margin: 10px;
  text-align: center;
  font-family: inherit;
  color: #737373;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
}
.login ion-row {
  justify-content: center;
}

.login ion-label {
  color: #737373;
}

.login_form {
  max-width: 650px;
}

.resetPassword_Button {
  font-size: 14px;
  text-decoration: underline;
  text-align: center;
}
