.dashboard_Title {
  height: 48px;
  margin: 10px 0px;
}

.dashboard_Card {
  height: 290px;
}

.dasboardCardHeader {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.dashboard_Card_Icon {
  width: 80px;
}

.dashboard_Card_Metric {
  text-align: center;
  font-weight: bold;
  font-size: xx-large;
}

.dashboard_Card_Title {
  text-transform: none;
  text-align: center;
  color: #4d4f5c;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.Test {
  border: 1px solid purple;
}
