/* ORYX Training Builder Tool 1.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * ORYX Custom colors. For more information see:
 * https://xd.adobe.com/view/df617fb9-9076-4eeb-a2d2-54a5a3a05da7-8a32/.
 */

:root {
  /** Gradients: **/
  --blueGradient: linear-gradient(180deg, #2066fa 0%, #1649d8 100%);
  --darkBlueGradient: linear-gradient(180deg, #1e3c72 0%, #2a5298 100%);
  --greenGradient: linear-gradient(180deg, #baff6b 0%, #ffe360 100%);
  --redGradient: linear-gradient(180deg, #f75442 0%, #e050a0 100%);
  --purpleGradient: linear-gradient(180deg, #b224ef 0%, #7579ff 100%);
  --greyGradient: linear-gradient(180deg, #29323c 0%, #485563 100%);

  /** Primary Colors: **/
  --primary900: #03308f;
  --primary900-rgb: 3, 48, 143;
  --ion-color-primary900: #03308f;
  --ion-color-primary900-rgb: 3, 48, 143;
  --ion-color-primary900-contrast: #ffffff;
  --ion-color-primary900-contrast-rgb: 255, 255, 255;
  --ion-color-primary900-shade: #032a7e;
  --ion-color-primary900-tint: #0546d1;

  --primary800: #043bb0;
  --primary800-rgb: 4, 59, 176;
  --ion-color-primary800: #043bb0;
  --ion-color-primary800-rgb: 4, 59, 176;
  --ion-color-primary800-contrast: #ffffff;
  --ion-color-primary800-contrast-rgb: 255, 255, 255;
  --ion-color-primary800-shade: #04349b;
  --ion-color-primary800-tint: #0552f3;

  --primary700: #0546d1;
  --primary700-rgb: 5, 70, 209;
  --ion-color-primary700: #0546d1;
  --ion-color-primary700-rgb: 5, 70, 209;
  --ion-color-primary700-contrast: #ffffff;
  --ion-color-primary700-contrast-rgb: 255, 255, 255;
  --ion-color-primary700-shade: #03308f;
  --ion-color-primary700-tint: #2066fa;

  --primary600: #0552f3;
  --primary600-rgb: 5, 82, 243;
  --ion-color-primary600: #0552f3;
  --ion-color-primary600-rgb: 5, 82, 243;
  --ion-color-primary600-contrast: #ffffff;
  --ion-color-primary600-contrast-rgb: 255, 255, 255;
  --ion-color-primary600-shade: #043bb0;
  --ion-color-primary600-tint: #417dfb;

  --primary: #2066fa;
  --primary-rgb: 32, 102, 250;

  --primary400: #417dfb;
  --primary400-rgb: 65, 125, 251;
  --ion-color-primary400: #417dfb;
  --ion-color-primary400-rgb: 65, 125, 251;
  --ion-color-primary400-contrast: #ffffff;
  --ion-color-primary400-contrast-rgb: 255, 255, 255;
  --ion-color-primary400-shade: #0552f3;
  --ion-color-primary400-tint: #84aafc;

  --primary300: #6394fb;
  --primary300-rgb: 99, 148, 251;
  --ion-color-primary300: #6394fb;
  --ion-color-primary300-rgb: 99, 148, 251;
  --ion-color-primary300-contrast: #707070;
  --ion-color-primary300-contrast-rgb: 112, 112, 112;
  --ion-color-primary300-shade: #2066fa;
  --ion-color-primary300-tint: #a5c1fd;

  --primary200: #84aafc;
  --primary200-rgb: 132, 170, 252;
  --ion-color-primary200: #84aafc;
  --ion-color-primary200-rgb: 132, 170, 252;
  --ion-color-primary200-contrast: #707070;
  --ion-color-primary200-contrast-rgb: 112, 112, 112;
  --ion-color-primary200-shade: #0552f3;
  --ion-color-primary200-tint: #90b3fc;

  --primary100: #a5c1fd;
  --primary100-rgb: 165, 193, 253;
  --ion-color-primary100: #a5c1fd;
  --ion-color-primary100-rgb: 165, 193, 253;
  --ion-color-primary100-contrast: #707070;
  --ion-color-primary100-contrast-rgb: 112, 112, 112;
  --ion-color-primary100-shade: #6394fb;
  --ion-color-primary100-tint: #aec7fd;

  /** Secondary colors **/
  --secondary900: #79e200;
  --secondary900-rgb: 121, 226, 0;
  --ion-color-secondary900: #79e200;
  --ion-color-secondary900-rgb: 121, 226, 0;
  --ion-color-secondary900-contrast: #707070;
  --ion-color-secondary900-contrast-rgb: 112, 112, 112;
  --ion-color-secondary900-shade: #6ac700;
  --ion-color-secondary900-tint: #9aff27;

  --secondary800: #8aff05;
  --secondary800-rgb: 138, 255, 5;
  --ion-color-secondary800: #8aff05;
  --ion-color-secondary800-rgb: 138, 255, 5;
  --ion-color-secondary800-contrast: #707070;
  --ion-color-secondary800-contrast-rgb: 112, 112, 112;
  --ion-color-secondary800-shade: #79e004;
  --ion-color-secondary800-tint: #aaff49;

  --secondary700: #9aff27;
  --secondary700-rgb: 154, 255, 39;
  --ion-color-secondary700: #9aff27;
  --ion-color-secondary700-rgb: 154, 255, 39;
  --ion-color-secondary700-contrast: #707070;
  --ion-color-secondary700-contrast-rgb: 112, 112, 112;
  --ion-color-secondary700-shade: #79e200;
  --ion-color-secondary700-tint: #baff6b;

  --secondary600: #aaff49;
  --secondary600-rgb: 170, 255, 73;
  --ion-color-secondary600: #aaff49;
  --ion-color-secondary600-rgb: 170, 255, 73;
  --ion-color-secondary600-contrast: #707070;
  --ion-color-secondary600-contrast-rgb: 112, 112, 112;
  --ion-color-secondary600-shade: #8aff05;
  --ion-color-secondary600-tint: #caff8d;

  --secondary: #baff6b;
  --secondary-rgb: 186, 255, 107;

  --secondary400: #caff8d;
  --secondary400-rgb: 202, 255, 141;
  --ion-color-secondary400: #caff8d;
  --ion-color-secondary400-rgb: 202, 255, 141;
  --ion-color-secondary400-contrast: #707070;
  --ion-color-secondary400-contrast-rgb: 112, 112, 112;
  --ion-color-secondary400-shade: #aaff49;
  --ion-color-secondary400-tint: #eaffd1;

  --secondary300: #daffaf;
  --secondary300-rgb: 218, 255, 175;
  --ion-color-secondary300: #daffaf;
  --ion-color-secondary300-rgb: 218, 255, 175;
  --ion-color-secondary300-contrast: #707070;
  --ion-color-secondary300-contrast-rgb: 112, 112, 112;
  --ion-color-secondary300-shade: #baff6b;
  --ion-color-secondary300-tint: #f9fff3;

  --secondary200: #eaffd1;
  --secondary200-rgb: 234, 255, 209;
  --ion-color-secondary200: #eaffd1;
  --ion-color-secondary200-rgb: 234, 255, 209;
  --ion-color-secondary200-contrast: #707070;
  --ion-color-secondary200-contrast-rgb: 112, 112, 112;
  --ion-color-secondary200-shade: #caff8d;
  --ion-color-secondary200-tint: #ecffd6;

  --secondary100: #f9fff3;
  --secondary100-rgb: 249, 255, 243;
  --ion-color-secondary100: #f9fff3;
  --ion-color-secondary100-rgb: 249, 255, 243;
  --ion-color-secondary100-contrast: #707070;
  --ion-color-secondary100-contrast-rgb: 112, 112, 112;
  --ion-color-secondary100-shade: #daffaf;
  --ion-color-secondary100-tint: #fafff4;

  /** Tertiary colors **/
  --tertiary900: #8f195b;
  --tertiary900-rgb: 143, 25, 91;
  --ion-color-tertiary900: #8f195b;
  --ion-color-tertiary900-rgb: 143, 25, 91;
  --ion-color-tertiary900-contrast: #ffffff;
  --ion-color-tertiary900-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary900-shade: #7e1650;
  --ion-color-tertiary900-tint: #c8247f;

  --tertiary800: #ac1e6d;
  --tertiary800-rgb: 172, 30, 109;
  --ion-color-tertiary800: #ac1e6d;
  --ion-color-tertiary800-rgb: 172, 30, 109;
  --ion-color-tertiary800-contrast: #ffffff;
  --ion-color-tertiary800-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary800-shade: #971a60;
  --ion-color-tertiary800-tint: #db3390;

  --tertiary700: #c8247f;
  --tertiary700-rgb: 200, 36, 127;
  --ion-color-tertiary700: #c8247f;
  --ion-color-tertiary700-rgb: 200, 36, 127;
  --ion-color-tertiary700-contrast: #ffffff;
  --ion-color-tertiary700-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary700-shade: #8f195b;
  --ion-color-tertiary700-tint: #e050a0;

  --tertiary600: #db3390;
  --tertiary600-rgb: 219, 51, 144;
  --ion-color-tertiary600: #db3390;
  --ion-color-tertiary600-rgb: 219, 51, 144;
  --ion-color-tertiary600-contrast: #ffffff;
  --ion-color-tertiary600-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary600-shade: #ac1e6d;
  --ion-color-tertiary600-tint: #e56db0;

  --tertiary: #e050a0;
  --tertiary-rgb: 224, 80, 160;

  --tertiary400: #e56db0;
  --tertiary400-rgb: 229, 109, 176;
  --ion-color-tertiary400: #e56db0;
  --ion-color-tertiary400-rgb: 229, 109, 176;
  --ion-color-tertiary400-contrast: #707070;
  --ion-color-tertiary400-contrast-rgb: 112, 112, 112;
  --ion-color-tertiary400-shade: #db3390;
  --ion-color-tertiary400-tint: #efa7cf;

  --tertiary300: #ea8abf;
  --tertiary300-rgb: 234, 138, 191;
  --ion-color-tertiary300: #ea8abf;
  --ion-color-tertiary300-rgb: 234, 138, 191;
  --ion-color-tertiary300-contrast: #707070;
  --ion-color-tertiary300-contrast-rgb: 112, 112, 112;
  --ion-color-tertiary300-shade: #e050a0;
  --ion-color-tertiary300-tint: #f4c4df;

  --tertiary200: #efa7cf;
  --tertiary200-rgb: 239, 167, 207;
  --ion-color-tertiary200: #efa7cf;
  --ion-color-tertiary200-rgb: 239, 167, 207;
  --ion-color-tertiary200-contrast: #707070;
  --ion-color-tertiary200-contrast-rgb: 112, 112, 112;
  --ion-color-tertiary200-shade: #e56db0;
  --ion-color-tertiary200-tint: #f1b0d4;

  --tertiary100: #f4c4df;
  --tertiary100-rgb: 244, 196, 223;
  --ion-color-tertiary100: #f4c4df;
  --ion-color-tertiary100-rgb: 244, 196, 223;
  --ion-color-tertiary100-contrast: #707070;
  --ion-color-tertiary100-contrast-rgb: 112, 112, 112;
  --ion-color-tertiary100-shade: #ea8abf;
  --ion-color-tertiary100-tint: #f5cae2;

  /** Quaternary colors **/
  --quaternary900: #aa1707;
  --quaternary900-rgb: 170, 23, 7;
  --ion-color-quaternary900: #aa1707;
  --ion-color-quaternary900-rgb: 170, 23, 7;
  --ion-color-quaternary900-contrast: #ffffff;
  --ion-color-quaternary900-contrast-rgb: 255, 255, 255;
  --ion-color-quaternary900-shade: #961406;
  --ion-color-quaternary900-tint: #eb200a;

  --quaternary800: #ca1c09;
  --quaternary800-rgb: 202, 28, 9;
  --ion-color-quaternary800: #ca1c09;
  --ion-color-quaternary800-rgb: 202, 28, 9;
  --ion-color-quaternary800-contrast: #ffffff;
  --ion-color-quaternary800-contrast-rgb: 255, 255, 255;
  --ion-color-quaternary800-shade: #b21908;
  --ion-color-quaternary800-tint: #f63621;

  --quaternary700: #eb200a;
  --quaternary700-rgb: 235, 32, 10;
  --ion-color-quaternary700: #eb200a;
  --ion-color-quaternary700-rgb: 235, 32, 10;
  --ion-color-quaternary700-contrast: #ffffff;
  --ion-color-quaternary700-contrast-rgb: 255, 255, 255;
  --ion-color-quaternary700-shade: #aa1707;
  --ion-color-quaternary700-tint: #f75442;

  --quaternary600: #f63621;
  --quaternary600-rgb: 246, 54, 33;
  --ion-color-quaternary600: #f63621;
  --ion-color-quaternary600-rgb: 246, 54, 33;
  --ion-color-quaternary600-contrast: #ffffff;
  --ion-color-quaternary600-contrast-rgb: 255, 255, 255;
  --ion-color-quaternary600-shade: #ca1c09;
  --ion-color-quaternary600-tint: #f87263;

  --quaternary500: #f75442;
  --quaternary500-rgb: 247, 84, 66;
  --ion-color-quaternary500: #f75442;
  --ion-color-quaternary500-rgb: 247, 84, 66;
  --ion-color-quaternary500-contrast: #707070;
  --ion-color-quaternary500-contrast-rgb: 112, 112, 112;
  --ion-color-quaternary500-shade: #eb200a;
  --ion-color-quaternary500-tint: #fa8f83;

  --quaternary400: #f87263;
  --quaternary400-rgb: 248, 114, 99;
  --ion-color-quaternary400: #f87263;
  --ion-color-quaternary400-rgb: 248, 114, 99;
  --ion-color-quaternary400-contrast: #707070;
  --ion-color-quaternary400-contrast-rgb: 112, 112, 112;
  --ion-color-quaternary400-shade: #f63621;
  --ion-color-quaternary400-tint: #fbada4;

  --quaternary300: #fa8f83;
  --quaternary300-rgb: 250, 143, 131;
  --ion-color-quaternary300: #fa8f83;
  --ion-color-quaternary300-rgb: 250, 143, 131;
  --ion-color-quaternary300-contrast: #707070;
  --ion-color-quaternary300-contrast-rgb: 112, 112, 112;
  --ion-color-quaternary300-shade: #f75442;
  --ion-color-quaternary300-tint: #fdcac4;

  --quaternary200: #fbada4;
  --quaternary200-rgb: 251, 173, 164;
  --ion-color-quaternary200: #fbada4;
  --ion-color-quaternary200-rgb: 251, 173, 164;
  --ion-color-quaternary200-contrast: #707070;
  --ion-color-quaternary200-contrast-rgb: 112, 112, 112;
  --ion-color-quaternary200-shade: #f87263;
  --ion-color-quaternary200-tint: #fbb5ad;

  --quaternary100: #fdcac4;
  --quaternary100-rgb: 253, 202, 196;
  --ion-color-quaternary100: #fdcac4;
  --ion-color-quaternary100-rgb: 253, 202, 196;
  --ion-color-quaternary100-contrast: #707070;
  --ion-color-quaternary100-contrast-rgb: 112, 112, 112;
  --ion-color-quaternary100-shade: #fa8f83;
  --ion-color-quaternary100-tint: #fdcfca;

  /** Primary Grey **/
  --primary-grey900: #11111c;
  --primary-grey900-rgb: 17, 17, 28;
  --ion-color-primary-grey900: #11111c;
  --ion-color-primary-grey900-rgb: 17, 17, 28;
  --ion-color-primary-grey900-contrast: #ffffff;
  --ion-color-primary-grey900-contrast-rgb: 255, 255, 255;
  --ion-color-primary-grey900-shade: #0f0f19;
  --ion-color-primary-grey900-tint: #241e32;

  --primary-grey800: #1a1727;
  --primary-grey800-rgb: 26, 23, 39;
  --ion-color-primary-grey800: #1a1727;
  --ion-color-primary-grey800-rgb: 26, 23, 39;
  --ion-color-primary-grey800-contrast: #ffffff;
  --ion-color-primary-grey800-contrast-rgb: 255, 255, 255;
  --ion-color-primary-grey800-shade: #171422;
  --ion-color-primary-grey800-tint: #2f243d;

  --primary-grey700: #241e32;
  --primary-grey700-rgb: 36, 30, 50;
  --ion-color-primary-grey700: #241e32;
  --ion-color-primary-grey700-rgb: 36, 30, 50;
  --ion-color-primary-grey700-contrast: #ffffff;
  --ion-color-primary-grey700-contrast-rgb: 255, 255, 255;
  --ion-color-primary-grey700-shade: #11111c;
  --ion-color-primary-grey700-tint: #3b2b48;

  --primary-grey600: #2f243d;
  --primary-grey600-rgb: 47, 36, 61;
  --ion-color-primary-grey600: #2f243d;
  --ion-color-primary-grey600-rgb: 47, 36, 61;
  --ion-color-primary-grey600-contrast: #ffffff;
  --ion-color-primary-grey600-contrast-rgb: 255, 255, 255;
  --ion-color-primary-grey600-shade: #1a1727;
  --ion-color-primary-grey600-tint: #534a63;

  --primary-grey500: #3b2b48;
  --primary-grey500-rgb: 59, 43, 72;
  --ion-color-primary-grey500: #3b2b48;
  --ion-color-primary-grey500-rgb: 59, 43, 72;
  --ion-color-primary-grey500-contrast: #ffffff;
  --ion-color-primary-grey500-contrast-rgb: 255, 255, 255;
  --ion-color-primary-grey500-shade: #241e32;
  --ion-color-primary-grey500-tint: #6c697d;

  --primary-grey400: #534a63;
  --primary-grey400-rgb: 83, 74, 99;
  --ion-color-primary-grey400: #534a63;
  --ion-color-primary-grey400-rgb: 83, 74, 99;
  --ion-color-primary-grey400-contrast: #ffffff;
  --ion-color-primary-grey400-contrast-rgb: 255, 255, 255;
  --ion-color-primary-grey400-shade: #2f243d;
  --ion-color-primary-grey400-tint: #878798;

  --primary-grey300: #6c697d;
  --primary-grey300-rgb: 108, 105, 125;
  --ion-color-primary-grey300: #6c697d;
  --ion-color-primary-grey300-rgb: 108, 105, 125;
  --ion-color-primary-grey300-contrast: #ffffff;
  --ion-color-primary-grey300-contrast-rgb: 255, 255, 255;
  --ion-color-primary-grey300-shade: #3b2b48;
  --ion-color-primary-grey300-tint: #a6a8b2;

  --primary-grey200: #878798;
  --primary-grey200-rgb: 135, 135, 152;
  --ion-color-primary-grey200: #878798;
  --ion-color-primary-grey200-rgb: 135, 135, 152;
  --ion-color-primary-grey200-contrast: #707070;
  --ion-color-primary-grey200-contrast-rgb: 112, 112, 112;
  --ion-color-primary-grey200-shade: #534a63;
  --ion-color-primary-grey200-tint: #c0c1d6;

  --primary-grey100: #a6a8b2;
  --primary-grey100-rgb: 166, 168, 178;
  --ion-color-primary-grey100: #a6a8b2;
  --ion-color-primary-grey100-rgb: 166, 168, 178;
  --ion-color-primary-grey100-contrast: #707070;
  --ion-color-primary-grey100-contrast-rgb: 112, 112, 112;
  --ion-color-primary-grey100-shade: #6c697d;
  --ion-color-primary-grey100-tint: #dcdde1;

  /** Succes **/
  --success950: #14532d;
  --success950-rgb: 20, 83, 45;
  --ion-color-succes950: #14532d;
  --ion-color-succes950-rgb: 20, 83, 45;
  --ion-color-succes950-contrast: #ffffff;
  --ion-color-succes950-contrast-rgb: 255, 255, 255;
  --ion-color-succes950-shade: #124928;
  --ion-color-succes950-tint: #15803d;

  --success900: #166534;
  --success900-rgb: 22, 101, 52;
  --ion-color-succes900: #166534;
  --ion-color-succes900-rgb: 22, 101, 52;
  --ion-color-succes900-contrast: #ffffff;
  --ion-color-succes900-contrast-rgb: 255, 255, 255;
  --ion-color-succes900-shade: #13592e;
  --ion-color-succes900-tint: #16a34a;

  --success800: #15803d;
  --success800-rgb: 21, 128, 61;
  --ion-color-success800: #15803d;
  --ion-color-success800-rgb: 21, 128, 61;
  --ion-color-success800-contrast: #ffffff;
  --ion-color-success800-contrast-rgb: 255, 255, 255;
  --ion-color-success800-shade: #14532d;
  --ion-color-success800-tint: #22c55e;

  --success700: #16a34a;
  --success700-rgb: 22, 163, 74;
  --ion-color-success700: #16a34a;
  --ion-color-success700-rgb: 22, 163, 74;
  --ion-color-success700-contrast: #ffffff;
  --ion-color-success700-contrast-rgb: 255, 255, 255;
  --ion-color-success700-shade: #166534;
  --ion-color-success700-tint: #4ade80;

  --success600: #22c55e;
  --success: #22c55e;
  --success600-rgb: 34, 197, 94;
  --ion-color-success600: #22c55e;
  --ion-color-success600-rgb: 34, 197, 94;
  --ion-color-success600-contrast: #707070;
  --ion-color-success600-contrast-rgb: 112, 112, 112;
  --ion-color-success600-shade: #15803d;
  --ion-color-success600-tint: #86efac;

  --success500: #4ade80;
  --success500-rgb: 74, 222, 128;
  --ion-color-success500: #4ade80;
  --ion-color-success500-rgb: 74, 222, 128;
  --ion-color-success500-contrast: #707070;
  --ion-color-success500-contrast-rgb: 112, 112, 112;
  --ion-color-success500-shade: #16a34a;
  --ion-color-success500-tint: #bbf7d0;

  --success400: #86efac;
  --success400-rgb: 134, 239, 172;
  --ion-color-success400: #86efac;
  --ion-color-success400-rgb: 134, 239, 172;
  --ion-color-success400-contrast: #707070;
  --ion-color-success400-contrast-rgb: 112, 112, 112;
  --ion-color-success400-shade: #22c55e;
  --ion-color-success400-tint: #dcfce7;

  --success300: #bbf7d0;
  --success300-rgb: 187, 247, 208;
  --ion-color-success300: #bbf7d0;
  --ion-color-success300-rgb: 187, 247, 208;
  --ion-color-success300-contrast: #707070;
  --ion-color-success300-contrast-rgb: 112, 112, 112;
  --ion-color-success300-shade: #4ade80;
  --ion-color-success300-tint: #edfdf2;

  --success200: #dcfce7;
  --success200-rgb: 220, 252, 231;
  --ion-color-success200: #dcfce7;
  --ion-color-success200-rgb: 220, 252, 231;
  --ion-color-success200-contrast: #707070;
  --ion-color-success200-contrast-rgb: 112, 112, 112;
  --ion-color-success200-shade: #86efac;
  --ion-color-success200-tint: #e0fce9;

  --success100: #edfdf2;
  --success100-rgb: 237, 253, 242;
  --ion-color-success100: #edfdf2;
  --ion-color-success100-rgb: 237, 253, 242;
  --ion-color-success100-contrast: #707070;
  --ion-color-success100-contrast-rgb: 112, 112, 112;
  --ion-color-success100-shade: #bbf7d0;
  --ion-color-success100-tint: #effdf3;

  /** Warning **/
  --warning950: #7c2712;
  --warning950-rgb: 124, 39, 18;
  --ion-color-warning950: #7c2712;
  --ion-color-warning950-rgb: 124, 39, 18;
  --ion-color-warning950-contrast: #ffffff;
  --ion-color-warning950-contrast-rgb: 255, 255, 255;
  --ion-color-warning950-shade: #6d2210;
  --ion-color-warning950-tint: #c23d0c;

  --warning900: #9a3212;
  --warning900-rgb: 154, 50, 18;
  --ion-color-warning900: #9a3212;
  --ion-color-warning900-rgb: 154, 50, 18;
  --ion-color-warning900-contrast: #ffffff;
  --ion-color-warning900-contrast-rgb: 255, 255, 255;
  --ion-color-warning900-shade: #882c10;
  --ion-color-warning900-tint: #ea4f0c;

  --warning800: #c23d0c;
  --warning800-rgb: 194, 61, 12;
  --ion-color-warning800: #c23d0c;
  --ion-color-warning800-rgb: 194, 61, 12;
  --ion-color-warning800-contrast: #ffffff;
  --ion-color-warning800-contrast-rgb: 255, 255, 255;
  --ion-color-warning800-shade: #7c2712;
  --ion-color-warning800-tint: #f97316;

  --warning700: #ea4f0c;
  --warning700-rgb: 234, 79, 12;
  --ion-color-warning700: #ea4f0c;
  --ion-color-warning700-rgb: 234, 79, 12;
  --ion-color-warning700-contrast: #ffffff;
  --ion-color-warning700-contrast-rgb: 255, 255, 255;
  --ion-color-warning700-shade: #9a3212;
  --ion-color-warning700-tint: #fb923c;

  --warning600: #f97316;
  --warning: #f97316;
  --warning600-rgb: 249, 115, 22;
  --ion-color-warning600: #f97316;
  --ion-color-warning600-rgb: 249, 115, 22;
  --ion-color-warning600-contrast: #707070;
  --ion-color-warning600-contrast-rgb: 112, 112, 112;
  --ion-color-warning600-shade: #c23d0c;
  --ion-color-warning600-tint: #fdba74;

  --warning500: #fb923c;
  --warning500-rgb: 251, 146, 60;
  --ion-color-warning500: #fb923c;
  --ion-color-warning500-rgb: 251, 146, 60;
  --ion-color-warning500-contrast: #707070;
  --ion-color-warning500-contrast-rgb: 112, 112, 112;
  --ion-color-warning500-shade: #ea4f0c;
  --ion-color-warning500-tint: #fed7aa;

  --warning400: #fdba74;
  --warning400-rgb: 253, 186, 116;
  --ion-color-warning400: #fdba74;
  --ion-color-warning400-rgb: 253, 186, 116;
  --ion-color-warning400-contrast: #707070;
  --ion-color-warning400-contrast-rgb: 112, 112, 112;
  --ion-color-warning400-shade: #f97316;
  --ion-color-warning400-tint: #ffedd5;

  --warning300: #fed7aa;
  --warning300-rgb: 254, 215, 170;
  --ion-color-warning300: #fed7aa;
  --ion-color-warning300-rgb: 254, 215, 170;
  --ion-color-warning300-contrast: #707070;
  --ion-color-warning300-contrast-rgb: 112, 112, 112;
  --ion-color-warning300-shade: #fb923c;
  --ion-color-warning300-tint: #fff4e5;

  --warning200: #ffedd5;
  --warning200-rgb: 255, 237, 213;
  --ion-color-warning200: #ffedd5;
  --ion-color-warning200-rgb: 255, 237, 213;
  --ion-color-warning200-contrast: #707070;
  --ion-color-warning200-contrast-rgb: 112, 112, 112;
  --ion-color-warning200-shade: #fdba74;
  --ion-color-warning200-tint: #ffefd9;

  --warning100: #fff4e5;
  --warning100-rgb: 255, 244, 229;
  --ion-color-warning100: #fff4e5;
  --ion-color-warning100-rgb: 255, 244, 229;
  --ion-color-warning100-contrast: #707070;
  --ion-color-warning100-contrast-rgb: 112, 112, 112;
  --ion-color-warning100-shade: #fed7aa;
  --ion-color-warning100-tint: #fff5e8;

  /** Error **/
  --error950: #790c0c;
  --error950-rgb: 121, 12, 12;
  --ion-color-error950: #790c0c;
  --ion-color-error950-rgb: 121, 12, 12;
  --ion-color-error950-contrast: #ffffff;
  --ion-color-error950-contrast-rgb: 255, 255, 255;
  --ion-color-error950-shade: #6a0b0b;
  --ion-color-error950-tint: #c21313;

  --error900: #950f0f;
  --error900-rgb: 149, 15, 15;
  --ion-color-error900: #950f0f;
  --ion-color-error900-rgb: 149, 15, 15;
  --ion-color-error900-contrast: #ffffff;
  --ion-color-error900-contrast-rgb: 255, 255, 255;
  --ion-color-error900-shade: #830d0d;
  --ion-color-error900-tint: #e81a1a;

  --error800: #c21313;
  --error800-rgb: 194, 19, 19;
  --ion-color-error800: #c21313;
  --ion-color-error800-rgb: 194, 19, 19;
  --ion-color-error800-contrast: #ffffff;
  --ion-color-error800-contrast-rgb: 255, 255, 255;
  --ion-color-error800-shade: #790c0c;
  --ion-color-error800-tint: #eb3737;

  --error700: #e81a1a;
  --error700-rgb: 232, 26, 26;
  --ion-color-error700: #e81a1a;
  --ion-color-error700-rgb: 232, 26, 26;
  --ion-color-error700-contrast: #ffffff;
  --ion-color-error700-contrast-rgb: 255, 255, 255;
  --ion-color-error700-shade: #950f0f;
  --ion-color-error700-tint: #f87171;

  --error600: #eb3737;
  --error: #eb3737;
  --error600-rgb: 235, 55, 55;
  --ion-color-error600: #eb3737;
  --ion-color-error600-rgb: 235, 55, 55;
  --ion-color-error600-contrast: #ffffff;
  --ion-color-error600-contrast-rgb: 255, 255, 255;
  --ion-color-error600-shade: #c21313;
  --ion-color-error600-tint: #fca5a5;

  --error500: #f87171;
  --error500-rgb: 248, 113, 113;
  --ion-color-error500: #f87171;
  --ion-color-error500-rgb: 248, 113, 113;
  --ion-color-error500-contrast: #707070;
  --ion-color-error500-contrast-rgb: 112, 112, 112;
  --ion-color-error500-shade: #e81a1a;
  --ion-color-error500-tint: #fecaca;

  --error400: #fca5a5;
  --error400-rgb: 252, 165, 165;
  --ion-color-error400: #fca5a5;
  --ion-color-error400-rgb: 252, 165, 165;
  --ion-color-error400-contrast: #707070;
  --ion-color-error400-contrast-rgb: 112, 112, 112;
  --ion-color-error400-shade: #eb3737;
  --ion-color-error400-tint: #fee2e2;

  --error300: #fecaca;
  --error300-rgb: 254, 202, 202;
  --ion-color-error300: #fecaca;
  --ion-color-error300-rgb: 254, 202, 202;
  --ion-color-error300-contrast: #707070;
  --ion-color-error300-contrast-rgb: 112, 112, 112;
  --ion-color-error300-shade: #f87171;
  --ion-color-error300-tint: #fee2e2;

  --error200: #fee2e2;
  --error200-rgb: 254, 226, 226;
  --ion-color-error200: #fee2e2;
  --ion-color-error200-rgb: 254, 226, 226;
  --ion-color-error200-contrast: #707070;
  --ion-color-error200-contrast-rgb: 112, 112, 112;
  --ion-color-error200-shade: #fca5a5;
  --ion-color-error200-tint: #fee5e5;

  --error100: #fee2e2;
  --error100-rgb: 254, 226, 226;
  --ion-color-error100: #fee2e2;
  --ion-color-error100-rgb: 254, 226, 226;
  --ion-color-error100-contrast: #707070;
  --ion-color-error100-contrast-rgb: 112, 112, 112;
  --ion-color-error100-shade: #fca5a5;
  --ion-color-error100-tint: #fee5e5;

  /** Teal **/
  --teal950: #134e4e;
  --teal950-rgb: 19, 78, 78;
  --ion-color-teal950: #134e4e;
  --ion-color-teal950-rgb: 19, 78, 78;
  --ion-color-teal950-contrast: #ffffff;
  --ion-color-teal950-contrast-rgb: 255, 255, 255;
  --ion-color-teal950-shade: #114545;
  --ion-color-teal950-tint: #0f7575;

  --teal900: #115f5f;
  --teal900-rgb: 17, 95, 95;
  --ion-color-teal900: #115f5f;
  --ion-color-teal900-rgb: 17, 95, 95;
  --ion-color-teal900-contrast: #ffffff;
  --ion-color-teal900-contrast-rgb: 255, 255, 255;
  --ion-color-teal900-shade: #0f5454;
  --ion-color-teal900-tint: #009e9e;

  --teal800: #0f7575;
  --teal800-rgb: 15, 117, 117;
  --ion-color-teal800: #0f7575;
  --ion-color-teal800-rgb: 15, 117, 117;
  --ion-color-teal800-contrast: #ffffff;
  --ion-color-teal800-contrast-rgb: 255, 255, 255;
  --ion-color-teal800-shade: #134e4e;
  --ion-color-teal800-tint: #14b8b8;

  --teal700: #009e9e;
  --teal700-rgb: 0, 158, 158;
  --ion-color-teal700: #009e9e;
  --ion-color-teal700-rgb: 0, 158, 158;
  --ion-color-teal700-contrast: #ffffff;
  --ion-color-teal700-contrast-rgb: 255, 255, 255;
  --ion-color-teal700-shade: #115f5f;
  --ion-color-teal700-tint: #2de1e1;

  --teal600: #14b8b8;
  --teal600-rgb: 20, 184, 184;
  --ion-color-teal600: #14b8b8;
  --ion-color-teal600-rgb: 20, 184, 184;
  --ion-color-teal600-contrast: #707070;
  --ion-color-teal600-contrast-rgb: 112, 112, 112;
  --ion-color-teal600-shade: #0f7575;
  --ion-color-teal600-tint: #5deaea;

  --teal500: #2de1e1;
  --teal500-rgb: 45, 225, 225;
  --ion-color-teal500: #2de1e1;
  --ion-color-teal500-rgb: 45, 225, 225;
  --ion-color-teal500-contrast: #707070;
  --ion-color-teal500-contrast-rgb: 112, 112, 112;
  --ion-color-teal500-shade: #009e9e;
  --ion-color-teal500-tint: #98f6f6;

  --teal400: #5deaea;
  --teal400-rgb: 93, 234, 234;
  --ion-color-teal400: #5deaea;
  --ion-color-teal400-rgb: 93, 234, 234;
  --ion-color-teal400-contrast: #707070;
  --ion-color-teal400-contrast-rgb: 112, 112, 112;
  --ion-color-teal400-shade: #14b8b8;
  --ion-color-teal400-tint: #cbfbfb;

  --teal300: #98f6f6;
  --teal300-rgb: rgb(152, 246, 246);
  --ion-color-teal300: #98f6f6;
  --ion-color-teal300-rgb: 152, 246, 246;
  --ion-color-teal300-contrast: #707070;
  --ion-color-teal300-contrast-rgb: 112, 112, 112;
  --ion-color-teal300-shade: #2de1e1;
  --ion-color-teal300-tint: #e4fbfb;

  --teal200: #cbfbfb;
  --teal200-rgb: rgb(203, 251, 251);
  --ion-color-teal200: #cbfbfb;
  --ion-color-teal200-rgb: 203, 251, 251;
  --ion-color-teal200-contrast: #707070;
  --ion-color-teal200-contrast-rgb: 112, 112, 112;
  --ion-color-teal200-shade: #5deaea;
  --ion-color-teal200-tint: #d0fbfb;

  --teal100: #e4fbfb;
  --teal100-rgb: rgb(228, 251, 251);
  --ion-color-teal100: #e4fbfb;
  --ion-color-teal100-rgb: 228, 251, 251;
  --ion-color-teal100-contrast: #707070;
  --ion-color-teal100-contrast-rgb: 112, 112, 112;
  --ion-color-teal100-shade: #98f6f6;
  --ion-color-teal100-tint: #e7fbfb;

  /** Lime **/
  --lime950: #335214;
  --lime950-rgb: 51, 82, 20;
  --ion-color-lime950: #335214;
  --ion-color-lime950-rgb: 51, 82, 20;
  --ion-color-lime950-contrast: #ffffff;
  --ion-color-lime950-contrast-rgb: 255, 255, 255;
  --ion-color-lime950-shade: #2d4812;
  --ion-color-lime950-tint: #457b0f;

  --lime900: #3b6312;
  --lime900-rgb: 59, 99, 18;
  --ion-color-lime900: #3b6312;
  --ion-color-lime900-rgb: 59, 99, 18;
  --ion-color-lime900-contrast: #ffffff;
  --ion-color-lime900-contrast-rgb: 255, 255, 255;
  --ion-color-lime900-shade: #2d4812;
  --ion-color-lime900-tint: #59a50d;

  --lime800: #457b0f;
  --lime800-rgb: 69, 123, 15;
  --ion-color-lime800: #457b0f;
  --ion-color-lime800-rgb: 69, 123, 15;
  --ion-color-lime800-contrast: #ffffff;
  --ion-color-lime800-contrast-rgb: 255, 255, 255;
  --ion-color-lime800-shade: #335214;
  --ion-color-lime800-tint: #70cb15;

  --lime700: #59a50d;
  --lime700-rgb: 89, 165, 13;
  --ion-color-lime700: #59a50d;
  --ion-color-lime700-rgb: 89, 165, 13;
  --ion-color-lime700-contrast: #ffffff;
  --ion-color-lime700-contrast-rgb: 255, 255, 255;
  --ion-color-lime700-shade: #3b6312;
  --ion-color-lime700-tint: #8fdd40;

  --lime600: #70cb15;
  --lime600-rgb: 112, 203, 21;
  --ion-color-lime600: #70cb15;
  --ion-color-lime600-rgb: 112, 203, 21;
  --ion-color-lime600-contrast: #707070;
  --ion-color-lime600-contrast-rgb: 112, 112, 112;
  --ion-color-lime600-shade: #457b0f;
  --ion-color-lime600-tint: #abf263;

  --lime500: #8fdd40;
  --lime500-rgb: 143, 221, 64;
  --ion-color-lime500: #8fdd40;
  --ion-color-lime500-rgb: 143, 221, 64;
  --ion-color-lime500-contrast: #707070;
  --ion-color-lime500-contrast-rgb: 112, 112, 112;
  --ion-color-lime500-shade: #59a50d;
  --ion-color-lime500-tint: #ccf99f;

  --lime400: #abf263;
  --lime400-rgb: 171, 242, 99;
  --ion-color-lime400: #abf263;
  --ion-color-lime400-rgb: 171, 242, 99;
  --ion-color-lime400-contrast: #707070;
  --ion-color-lime400-contrast-rgb: 112, 112, 112;
  --ion-color-lime400-shade: #70cb15;
  --ion-color-lime400-tint: #e3fcca;

  --lime300: #ccf99f;
  --lime300-rgb: 204, 249, 159;
  --ion-color-lime300: #ccf99f;
  --ion-color-lime300-rgb: 204, 249, 159;
  --ion-color-lime300-contrast: #707070;
  --ion-color-lime300-contrast-rgb: 112, 112, 112;
  --ion-color-lime300-shade: #8fdd40;
  --ion-color-lime300-tint: #f2fee7;

  --lime200: #e3fcca;
  --lime200-rgb: 227, 252, 202;
  --ion-color-lime200: #e3fcca;
  --ion-color-lime200-rgb: 227, 252, 202;
  --ion-color-lime200-contrast: #707070;
  --ion-color-lime200-contrast-rgb: 112, 112, 112;
  --ion-color-lime200-shade: #abf263;
  --ion-color-lime200-tint: #f9fff7;

  --lime100: #f2fee7;
  --lime100-rgb: 242, 254, 231;
  --ion-color-lime100: #f2fee7;
  --ion-color-lime100-rgb: 242, 254, 231;
  --ion-color-lime100-contrast: #707070;
  --ion-color-lime100-contrast-rgb: 112, 112, 112;
  --ion-color-lime100-shade: #ccf99f;
  --ion-color-lime100-tint: #fcfff9;

  /** Pink **/
  --pink950: #831843;
  --pink950-rgb: 131, 24, 67;
  --ion-color-pink950: #831843;
  --ion-color-pink950-rgb: 131, 24, 67;
  --ion-color-pink950-contrast: #ffffff;
  --ion-color-pink950-contrast-rgb: 255, 255, 255;
  --ion-color-pink950-shade: #5e112c;
  --ion-color-pink950-tint: #be185d;

  --pink900: #9d174d;
  --pink900-rgb: 157, 23, 77;
  --ion-color-pink900: #9d174d;
  --ion-color-pink900-rgb: 157, 23, 77;
  --ion-color-pink900-contrast: #ffffff;
  --ion-color-pink900-contrast-rgb: 255, 255, 255;
  --ion-color-pink900-shade: #6f1135;
  --ion-color-pink900-tint: #db2777;

  --pink800: #be185d;
  --pink800-rgb: 190, 24, 93;
  --ion-color-pink800: #be185d;
  --ion-color-pink800-rgb: 190, 24, 93;
  --ion-color-pink800-contrast: #ffffff;
  --ion-color-pink800-contrast-rgb: 255, 255, 255;
  --ion-color-pink800-shade: #831843;
  --ion-color-pink800-tint: #ec4899;

  --pink700: #db2777;
  --pink700-rgb: 219, 39, 119;
  --ion-color-pink700: #db2777;
  --ion-color-pink700-rgb: 219, 39, 119;
  --ion-color-pink700-contrast: #ffffff;
  --ion-color-pink700-contrast-rgb: 255, 255, 255;
  --ion-color-pink700-shade: #9d174d;
  --ion-color-pink700-tint: #ff52ac;

  --pink600: #ec4899;
  --pink600-rgb: 236, 72, 153;
  --ion-color-pink600: #ec4899;
  --ion-color-pink600-rgb: 236, 72, 153;
  --ion-color-pink600-contrast: #ffffff;
  --ion-color-pink600-contrast-rgb: 255, 255, 255;
  --ion-color-pink600-shade: #be185d;
  --ion-color-pink600-tint: #f9a8d4;

  --pink500: #ff52ac;
  --pink500-rgb: 255, 82, 172;
  --ion-color-pink500: #ff52ac;
  --ion-color-pink500-rgb: 255, 82, 172;
  --ion-color-pink500-contrast: #ffffff;
  --ion-color-pink500-contrast-rgb: 255, 255, 255;
  --ion-color-pink500-shade: #db2777;
  --ion-color-pink500-tint: #fbcfe8;

  --pink400: #f9a8d4;
  --pink400-rgb: 249, 168, 212;
  --ion-color-pink400: #f9a8d4;
  --ion-color-pink400-rgb: 249, 168, 212;
  --ion-color-pink400-contrast: #707070;
  --ion-color-pink400-contrast-rgb: 112, 112, 112;
  --ion-color-pink400-shade: #ec4899;
  --ion-color-pink400-tint: #fce7f3;

  --pink300: #fbcfe8;
  --pink300-rgb: 251, 207, 232;
  --ion-color-pink300: #fbcfe8;
  --ion-color-pink300-rgb: 251, 207, 232;
  --ion-color-pink300-contrast: #707070;
  --ion-color-pink300-contrast-rgb: 112, 112, 112;
  --ion-color-pink300-shade: #ff52ac;
  --ion-color-pink300-tint: #fcedf5;

  --pink200: #fce7f3;
  --pink200-rgb: 252, 231, 243;
  --ion-color-pink200: #fce7f3;
  --ion-color-pink200-rgb: 252, 231, 243;
  --ion-color-pink200-contrast: #707070;
  --ion-color-pink200-contrast-rgb: 112, 112, 112;
  --ion-color-pink200-shade: #f9a8d4;
  --ion-color-pink200-tint: #fef5fb;

  --pink100: #fcedf5;
  --pink100-rgb: 252, 237, 245;
  --ion-color-pink100: #fcedf5;
  --ion-color-pink100-rgb: 252, 237, 245;
  --ion-color-pink100-contrast: #707070;
  --ion-color-pink100-contrast-rgb: 112, 112, 112;
  --ion-color-pink100-shade: #fbcfe8;
  --ion-color-pink100-tint: #fef5fb;

  /** Fuchsia **/
  --fuchsia950: #701a75;
  --fuchsia950-rgb: 112, 26, 117;
  --ion-color-fuchsia950: #701a75;
  --ion-color-fuchsia950-rgb: 112, 26, 117;
  --ion-color-fuchsia950-contrast: #ffffff;
  --ion-color-fuchsia950-contrast-rgb: 255, 255, 255;
  --ion-color-fuchsia950-shade: #4d144d;
  --ion-color-fuchsia950-tint: #a21caf;

  --fuchsia900: #86198f;
  --fuchsia900-rgb: 134, 25, 143;
  --ion-color-fuchsia900: #86198f;
  --ion-color-fuchsia900-rgb: 134, 25, 143;
  --ion-color-fuchsia900-contrast: #ffffff;
  --ion-color-fuchsia900-contrast-rgb: 255, 255, 255;
  --ion-color-fuchsia900-shade: #5e0f5e;
  --ion-color-fuchsia900-tint: #c026d3;

  --fuchsia800: #a21caf;
  --fuchsia800-rgb: 162, 28, 175;
  --ion-color-fuchsia800: #a21caf;
  --ion-color-fuchsia800-rgb: 162, 28, 175;
  --ion-color-fuchsia800-contrast: #ffffff;
  --ion-color-fuchsia800-contrast-rgb: 255, 255, 255;
  --ion-color-fuchsia800-shade: #701a75;
  --ion-color-fuchsia800-tint: #d946ef;

  --fuchsia700: #c026d3;
  --fuchsia700-rgb: 192, 38, 211;
  --ion-color-fuchsia700: #c026d3;
  --ion-color-fuchsia700-rgb: 192, 38, 211;
  --ion-color-fuchsia700-contrast: #ffffff;
  --ion-color-fuchsia700-contrast-rgb: 255, 255, 255;
  --ion-color-fuchsia700-shade: #86198f;
  --ion-color-fuchsia700-tint: #e879f9;

  --fuchsia600: #d946ef;
  --fuchsia600-rgb: 217, 70, 239;
  --ion-color-fuchsia600: #d946ef;
  --ion-color-fuchsia600-rgb: 217, 70, 239;
  --ion-color-fuchsia600-contrast: #ffffff;
  --ion-color-fuchsia600-contrast-rgb: 255, 255, 255;
  --ion-color-fuchsia600-shade: #a21caf;
  --ion-color-fuchsia600-tint: #f0abfc;

  --fuchsia500: #e879f9;
  --fuchsia500-rgb: 232, 121, 249;
  --ion-color-fuchsia500: #e879f9;
  --ion-color-fuchsia500-rgb: 232, 121, 249;
  --ion-color-fuchsia500-contrast: #ffffff;
  --ion-color-fuchsia500-contrast-rgb: 255, 255, 255;
  --ion-color-fuchsia500-shade: #c026d3;
  --ion-color-fuchsia500-tint: #f5d0fe;

  --fuchsia400: #f0abfc;
  --fuchsia400-rgb: 240, 171, 252;
  --ion-color-fuchsia400: #f0abfc;
  --ion-color-fuchsia400-rgb: 240, 171, 252;
  --ion-color-fuchsia400-contrast: #707070;
  --ion-color-fuchsia400-contrast-rgb: 112, 112, 112;
  --ion-color-fuchsia400-shade: #d946ef;
  --ion-color-fuchsia400-tint: #fae8ff;

  --fuchsia300: #f5d0fe;
  --fuchsia300-rgb: 245, 208, 254;
  --ion-color-fuchsia300: #f5d0fe;
  --ion-color-fuchsia300-rgb: 245, 208, 254;
  --ion-color-fuchsia300-contrast: #707070;
  --ion-color-fuchsia300-contrast-rgb: 112, 112, 112;
  --ion-color-fuchsia300-shade: #e879f9;
  --ion-color-fuchsia300-tint: #fdf4ff;

  --fuchsia200: #fae8ff;
  --fuchsia200-rgb: 250, 232, 255;
  --ion-color-fuchsia200: #fae8ff;
  --ion-color-fuchsia200-rgb: 250, 232, 255;
  --ion-color-fuchsia200-contrast: #707070;
  --ion-color-fuchsia200-contrast-rgb: 112, 112, 112;
  --ion-color-fuchsia200-shade: #f0abfc;
  --ion-color-fuchsia200-tint: #fef9ff;

  --fuchsia100: #fdf4ff;
  --fuchsia100-rgb: 253, 244, 255;
  --ion-color-fuchsia100: #fdf4ff;
  --ion-color-fuchsia100-rgb: 253, 244, 255;
  --ion-color-fuchsia100-contrast: #707070;
  --ion-color-fuchsia100-contrast-rgb: 112, 112, 112;
  --ion-color-fuchsia100-shade: #f5d0fe;
  --ion-color-fuchsia100-tint: #ffffff;

  /** Purple **/
  --purple950: #581c87;
  --purple950-rgb: 88, 28, 135;
  --ion-color-purple950: #581c87;
  --ion-color-purple950-rgb: 88, 28, 135;
  --ion-color-purple950-contrast: #ffffff;
  --ion-color-purple950-contrast-rgb: 255, 255, 255;
  --ion-color-purple950-shade: #3d145a;
  --ion-color-purple950-tint: #7e22ce;

  --purple900: #6b21a8;
  --purple900-rgb: 107, 33, 168;
  --ion-color-purple900: #6b21a8;
  --ion-color-purple900-rgb: 107, 33, 168;
  --ion-color-purple900-contrast: #ffffff;
  --ion-color-purple900-contrast-rgb: 255, 255, 255;
  --ion-color-purple900-shade: #4c1d95;
  --ion-color-purple900-tint: #9333ea;

  --purple800: #7e22ce;
  --purple800-rgb: 126, 34, 206;
  --ion-color-purple800: #7e22ce;
  --ion-color-purple800-rgb: 126, 34, 206;
  --ion-color-purple800-contrast: #ffffff;
  --ion-color-purple800-contrast-rgb: 255, 255, 255;
  --ion-color-purple800-shade: #581c87;
  --ion-color-purple800-tint: #a855f7;

  --purple700: #9333ea;
  --purple700-rgb: 147, 51, 234;
  --ion-color-purple700: #9333ea;
  --ion-color-purple700-rgb: 147, 51, 234;
  --ion-color-purple700-contrast: #ffffff;
  --ion-color-purple700-contrast-rgb: 255, 255, 255;
  --ion-color-purple700-shade: #6b21a8;
  --ion-color-purple700-tint: #c084fc;

  --purple600: #a855f7;
  --purple600-rgb: 168, 85, 247;
  --ion-color-purple600: #a855f7;
  --ion-color-purple600-rgb: 168, 85, 247;
  --ion-color-purple600-contrast: #ffffff;
  --ion-color-purple600-contrast-rgb: 255, 255, 255;
  --ion-color-purple600-shade: #7e22ce;
  --ion-color-purple600-tint: #d8b4fe;

  --purple500: #c084fc;
  --purple500-rgb: 192, 132, 252;
  --ion-color-purple500: #c084fc;
  --ion-color-purple500-rgb: 192, 132, 252;
  --ion-color-purple500-contrast: #ffffff;
  --ion-color-purple500-contrast-rgb: 255, 255, 255;
  --ion-color-purple500-shade: #9333ea;
  --ion-color-purple500-tint: #e9d5ff;

  --purple400: #d8b4fe;
  --purple400-rgb: 216, 180, 254;
  --ion-color-purple400: #d8b4fe;
  --ion-color-purple400-rgb: 216, 180, 254;
  --ion-color-purple400-contrast: #707070;
  --ion-color-purple400-contrast-rgb: 112, 112, 112;
  --ion-color-purple400-shade: #a855f7;
  --ion-color-purple400-tint: #f3e8ff;

  --purple300: #e9d5ff;
  --purple300-rgb: 233, 213, 255;
  --ion-color-purple300: #e9d5ff;
  --ion-color-purple300-rgb: 233, 213, 255;
  --ion-color-purple300-contrast: #707070;
  --ion-color-purple300-contrast-rgb: 112, 112, 112;
  --ion-color-purple300-shade: #c084fc;
  --ion-color-purple300-tint: #f7f0ff;

  --purple200: #f3e8ff;
  --purple200-rgb: 243, 232, 255;
  --ion-color-purple200: #f3e8ff;
  --ion-color-purple200-rgb: 243, 232, 255;
  --ion-color-purple200-contrast: #707070;
  --ion-color-purple200-contrast-rgb: 112, 112, 112;
  --ion-color-purple200-shade: #d8b4fe;
  --ion-color-purple200-tint: #ffffff;

  --purple100: #f7f0ff;
  --purple100-rgb: 247, 240, 255;
  --ion-color-purple100: #f7f0ff;
  --ion-color-purple100-rgb: 247, 240, 255;
  --ion-color-purple100-contrast: #707070;
  --ion-color-purple100-contrast-rgb: 112, 112, 112;
  --ion-color-purple100-shade: #e9d5ff;
  --ion-color-purple100-tint: #ffffff;

  /** Violet **/
  --violet950: #4c1d95;
  --violet950-rgb: 76, 29, 149;
  --ion-color-violet950: #4c1d95;
  --ion-color-violet950-rgb: 76, 29, 149;
  --ion-color-violet950-contrast: #ffffff;
  --ion-color-violet950-contrast-rgb: 255, 255, 255;
  --ion-color-violet950-shade: #3a176c;
  --ion-color-violet950-tint: #6d28d9;

  --violet900: #6000b3;
  --violet900-rgb: 96, 0, 179;
  --ion-color-violet900: #6000b3;
  --ion-color-violet900-rgb: 96, 0, 179;
  --ion-color-violet900-contrast: #ffffff;
  --ion-color-violet900-contrast-rgb: 255, 255, 255;
  --ion-color-violet900-shade: #4c1d95;
  --ion-color-violet900-tint: #7c3aed;

  --violet800: #6d28d9;
  --violet800-rgb: 109, 40, 217;
  --ion-color-violet800: #6d28d9;
  --ion-color-violet800-rgb: 109, 40, 217;
  --ion-color-violet800-contrast: #ffffff;
  --ion-color-violet800-contrast-rgb: 255, 255, 255;
  --ion-color-violet800-shade: #4c1d95;
  --ion-color-violet800-tint: #8b5cf6;

  --violet700: #7c3aed;
  --violet700-rgb: 124, 58, 237;
  --ion-color-violet700: #7c3aed;
  --ion-color-violet700-rgb: 124, 58, 237;
  --ion-color-violet700-contrast: #ffffff;
  --ion-color-violet700-contrast-rgb: 255, 255, 255;
  --ion-color-violet700-shade: #6000b3;
  --ion-color-violet700-tint: #a78bfa;

  --violet600: #8b5cf6;
  --violet600-rgb: 139, 92, 246;
  --ion-color-violet600: #8b5cf6;
  --ion-color-violet600-rgb: 139, 92, 246;
  --ion-color-violet600-contrast: #ffffff;
  --ion-color-violet600-contrast-rgb: 255, 255, 255;
  --ion-color-violet600-shade: #6d28d9;
  --ion-color-violet600-tint: #c4b5fd;

  --violet500: #a78bfa;
  --violet500-rgb: 167, 139, 250;
  --ion-color-violet500: #a78bfa;
  --ion-color-violet500-rgb: 167, 139, 250;
  --ion-color-violet500-contrast: #ffffff;
  --ion-color-violet500-contrast-rgb: 255, 255, 255;
  --ion-color-violet500-shade: #7c3aed;
  --ion-color-violet500-tint: #ddd6fe;

  --violet400: #c4b5fd;
  --violet400-rgb: 196, 181, 253;
  --ion-color-violet400: #c4b5fd;
  --ion-color-violet400-rgb: 196, 181, 253;
  --ion-color-violet400-contrast: #707070;
  --ion-color-violet400-contrast-rgb: 112, 112, 112;
  --ion-color-violet400-shade: #8b5cf6;
  --ion-color-violet400-tint: #ede9fe;

  --violet300: #ddd6fe;
  --violet300-rgb: 221, 214, 254;
  --ion-color-violet300: #ddd6fe;
  --ion-color-violet300-rgb: 221, 214, 254;
  --ion-color-violet300-contrast: #707070;
  --ion-color-violet300-contrast-rgb: 112, 112, 112;
  --ion-color-violet300-shade: #a78bfa;
  --ion-color-violet300-tint: #f5f3ff;

  --violet200: #ede9fe;
  --violet200-rgb: 237, 233, 254;
  --ion-color-violet200: #ede9fe;
  --ion-color-violet200-rgb: 237, 233, 254;
  --ion-color-violet200-contrast: #707070;
  --ion-color-violet200-contrast-rgb: 112, 112, 112;
  --ion-color-violet200-shade: #c4b5fd;
  --ion-color-violet200-tint: #f9f9ff;

  --violet100: #f5f3ff;
  --violet100-rgb: 245, 243, 255;
  --ion-color-violet100: #f5f3ff;
  --ion-color-violet100-rgb: 245, 243, 255;
  --ion-color-violet100-contrast: #707070;
  --ion-color-violet100-contrast-rgb: 112, 112, 112;
  --ion-color-violet100-shade: #ddd6fe;
  --ion-color-violet100-tint: #ffffff;

  /** Light blue **/
  --light-blue950: #0c4a6e;
  --light-blue950-rgb: 12, 74, 110;
  --ion-color-light-blue950: #0c4a6e;
  --ion-color-light-blue950-rgb: 12, 74, 110;
  --ion-color-light-blue950-contrast: #ffffff;
  --ion-color-light-blue950-contrast-rgb: 255, 255, 255;
  --ion-color-light-blue950-shade: #083b56;
  --ion-color-light-blue950-tint: #0369a1;

  --light-blue900: #075985;
  --light-blue900-rgb: 7, 89, 133;
  --ion-color-light-blue900: #075985;
  --ion-color-light-blue900-rgb: 7, 89, 133;
  --ion-color-light-blue900-contrast: #ffffff;
  --ion-color-light-blue900-contrast-rgb: 255, 255, 255;
  --ion-color-light-blue900-shade: #064e3b;
  --ion-color-light-blue900-tint: #0284c7;

  --light-blue800: #0369a1;
  --light-blue800-rgb: 3, 105, 161;
  --ion-color-light-blue800: #0369a1;
  --ion-color-light-blue800-rgb: 3, 105, 161;
  --ion-color-light-blue800-contrast: #ffffff;
  --ion-color-light-blue800-contrast-rgb: 255, 255, 255;
  --ion-color-light-blue800-shade: #0c4a6e;
  --ion-color-light-blue800-tint: #0ea5e9;

  --light-blue700: #0284c7;
  --light-blue700-rgb: 2, 132, 199;
  --ion-color-light-blue700: #0284c7;
  --ion-color-light-blue700-rgb: 2, 132, 199;
  --ion-color-light-blue700-contrast: #ffffff;
  --ion-color-light-blue700-contrast-rgb: 255, 255, 255;
  --ion-color-light-blue700-shade: #075985;
  --ion-color-light-blue700-tint: #38bdf8;

  --light-blue600: #0ea5e9;
  --light-blue600-rgb: 14, 165, 233;
  --ion-color-light-blue600: #0ea5e9;
  --ion-color-light-blue600-rgb: 14, 165, 233;
  --ion-color-light-blue600-contrast: #ffffff;
  --ion-color-light-blue600-contrast-rgb: 255, 255, 255;
  --ion-color-light-blue600-shade: #0369a1;
  --ion-color-light-blue600-tint: #7dd3fc;

  --light-blue500: #38bdf8;
  --light-blue500-rgb: 56, 189, 248;
  --ion-color-light-blue500: #38bdf8;
  --ion-color-light-blue500-rgb: 56, 189, 248;
  --ion-color-light-blue500-contrast: #707070;
  --ion-color-light-blue500-contrast-rgb: 112, 112, 112;
  --ion-color-light-blue500-shade: #0284c7;
  --ion-color-light-blue500-tint: #bae6fd;

  --light-blue400: #7dd3fc;
  --light-blue400-rgb: 125, 211, 252;
  --ion-color-light-blue400: #7dd3fc;
  --ion-color-light-blue400-rgb: 125, 211, 252;
  --ion-color-light-blue400-contrast: #707070;
  --ion-color-light-blue400-contrast-rgb: 112, 112, 112;
  --ion-color-light-blue400-shade: #0ea5e9;
  --ion-color-light-blue400-tint: #e0f2fe;

  --light-blue300: #bae6fd;
  --light-blue300-rgb: 186, 230, 253;
  --ion-color-light-blue300: #bae6fd;
  --ion-color-light-blue300-rgb: 186, 230, 253;
  --ion-color-light-blue300-contrast: #707070;
  --ion-color-light-blue300-contrast-rgb: 112, 112, 112;
  --ion-color-light-blue300-shade: #38bdf8;
  --ion-color-light-blue300-tint: #f0f9ff;

  --light-blue200: #e0f2fe;
  --light-blue200-rgb: 224, 242, 254;
  --ion-color-light-blue200: #e0f2fe;
  --ion-color-light-blue200-rgb: 224, 242, 254;
  --ion-color-light-blue200-contrast: #707070;
  --ion-color-light-blue200-contrast-rgb: 112, 112, 112;
  --ion-color-light-blue200-shade: #7dd3fc;
  --ion-color-light-blue200-tint: #f5faff;

  --light-blue100: #f0f9ff;
  --light-blue100-rgb: 240, 249, 255;
  --ion-color-light-blue100: #f0f9ff;
  --ion-color-light-blue100-rgb: 240, 249, 255;
  --ion-color-light-blue100-contrast: #707070;
  --ion-color-light-blue100-contrast-rgb: 112, 112, 112;
  --ion-color-light-blue100-shade: #bae6fd;
  --ion-color-light-blue100-tint: #f9faff;

  /** Emerald **/
  --emerald950: #064e3b;
  --emerald950-rgb: 6, 78, 59;
  --ion-color-emerald950: #064e3b;
  --ion-color-emerald950-rgb: 6, 78, 59;
  --ion-color-emerald950-contrast: #ffffff;
  --ion-color-emerald950-contrast-rgb: 255, 255, 255;
  --ion-color-emerald950-shade: #052c25;
  --ion-color-emerald950-tint: #047857;

  --emerald900: #065f46;
  --emerald900-rgb: 6, 95, 70;
  --ion-color-emerald900: #065f46;
  --ion-color-emerald900-rgb: 6, 95, 70;
  --ion-color-emerald900-contrast: #ffffff;
  --ion-color-emerald900-contrast-rgb: 255, 255, 255;
  --ion-color-emerald900-shade: #053a31;
  --ion-color-emerald900-tint: #059669;

  --emerald800: #047857;
  --emerald800-rgb: 4, 120, 87;
  --ion-color-emerald800: #047857;
  --ion-color-emerald800-rgb: 4, 120, 87;
  --ion-color-emerald800-contrast: #ffffff;
  --ion-color-emerald800-contrast-rgb: 255, 255, 255;
  --ion-color-emerald800-shade: #064e3b;
  --ion-color-emerald800-tint: #10b981;

  --emerald700: #059669;
  --emerald700-rgb: 5, 150, 105;
  --ion-color-emerald700: #059669;
  --ion-color-emerald700-rgb: 5, 150, 105;
  --ion-color-emerald700-contrast: #ffffff;
  --ion-color-emerald700-contrast-rgb: 255, 255, 255;
  --ion-color-emerald700-shade: #065f46;
  --ion-color-emerald700-tint: #34d399;

  --emerald600: #10b981;
  --emerald600-rgb: 16, 185, 129;
  --ion-color-emerald600: #10b981;
  --ion-color-emerald600-rgb: 16, 185, 129;
  --ion-color-emerald600-contrast: #ffffff;
  --ion-color-emerald600-contrast-rgb: 255, 255, 255;
  --ion-color-emerald600-shade: #047857;
  --ion-color-emerald600-tint: #6ee7b7;

  --emerald500: #34d399;
  --emerald500-rgb: 52, 211, 153;
  --ion-color-emerald500: #34d399;
  --ion-color-emerald500-rgb: 52, 211, 153;
  --ion-color-emerald500-contrast: #707070;
  --ion-color-emerald500-contrast-rgb: 112, 112, 112;
  --ion-color-emerald500-shade: #059669;
  --ion-color-emerald500-tint: #84fab0;

  --emerald400: #6ee7b7;
  --emerald400-rgb: 110, 231, 183;
  --ion-color-emerald400: #6ee7b7;
  --ion-color-emerald400-rgb: 110, 231, 183;
  --ion-color-emerald400-contrast: #707070;
  --ion-color-emerald400-contrast-rgb: 112, 112, 112;
  --ion-color-emerald400-shade: #10b981;
  --ion-color-emerald400-tint: #d1fae5;

  --emerald300: #a7f3d0;
  --emerald300-rgb: 167, 243, 208;
  --ion-color-emerald300: #a7f3d0;
  --ion-color-emerald300-rgb: 167, 243, 208;
  --ion-color-emerald300-contrast: #707070;
  --ion-color-emerald300-contrast-rgb: 112, 112, 112;
  --ion-color-emerald300-shade: #34d399;
  --ion-color-emerald300-tint: #dffcee;

  --emerald200: #d1fae5;
  --emerald200-rgb: 209, 250, 229;
  --ion-color-emerald200: #d1fae5;
  --ion-color-emerald200-rgb: 209, 250, 229;
  --ion-color-emerald200-contrast: #707070;
  --ion-color-emerald200-contrast-rgb: 112, 112, 112;
  --ion-color-emerald200-shade: #6ee7b7;
  --ion-color-emerald200-tint: #f0fdfa;

  --emerald100: #dffcee;
  --emerald100-rgb: 223, 252, 238;
  --ion-color-emerald100: #dffcee;
  --ion-color-emerald100-rgb: 223, 252, 238;
  --ion-color-emerald100-contrast: #707070;
  --ion-color-emerald100-contrast-rgb: 112, 112, 112;
  --ion-color-emerald100-shade: #a7f3d0;
  --ion-color-emerald100-tint: #f9fef7;

  /** Yellow **/
  --yellow950: #713f12;
  --yellow950-rgb: 113, 63, 18;
  --ion-color-yellow950: #713f12;
  --ion-color-yellow950-rgb: 113, 63, 18;
  --ion-color-yellow950-contrast: #ffffff;
  --ion-color-yellow950-contrast-rgb: 255, 255, 255;
  --ion-color-yellow950-shade: #5b2e0d;
  --ion-color-yellow950-tint: #a16207;

  --yellow900: #854d0e;
  --yellow900-rgb: 133, 77, 14;
  --ion-color-yellow900: #854d0e;
  --ion-color-yellow900-rgb: 133, 77, 14;
  --ion-color-yellow900-contrast: #ffffff;
  --ion-color-yellow900-contrast-rgb: 255, 255, 255;
  --ion-color-yellow900-shade: #633112;
  --ion-color-yellow900-tint: #ca8a04;

  --yellow800: #a16207;
  --yellow800-rgb: 161, 98, 7;
  --ion-color-yellow800: #a16207;
  --ion-color-yellow800-rgb: 161, 98, 7;
  --ion-color-yellow800-contrast: #ffffff;
  --ion-color-yellow800-contrast-rgb: 255, 255, 255;
  --ion-color-yellow800-shade: #713f12;
  --ion-color-yellow800-tint: #eab308;

  --yellow700: #ca8a04;
  --yellow700-rgb: 202, 138, 4;
  --ion-color-yellow700: #ca8a04;
  --ion-color-yellow700-rgb: 202, 138, 4;
  --ion-color-yellow700-contrast: #ffffff;
  --ion-color-yellow700-contrast-rgb: 255, 255, 255;
  --ion-color-yellow700-shade: #854d0e;
  --ion-color-yellow700-tint: #facc15;

  --yellow600: #eab308;
  --yellow600-rgb: 234, 179, 8;
  --ion-color-yellow600: #eab308;
  --ion-color-yellow600-rgb: 234, 179, 8;
  --ion-color-yellow600-contrast: #707070;
  --ion-color-yellow600-contrast-rgb: 112, 112, 112;
  --ion-color-yellow600-shade: #a16207;
  --ion-color-yellow600-tint: #fde047;

  --yellow500: #facc15;
  --yellow500-rgb: 250, 204, 21;
  --ion-color-yellow500: #facc15;
  --ion-color-yellow500-rgb: 250, 204, 21;
  --ion-color-yellow500-contrast: #707070;
  --ion-color-yellow500-contrast-rgb: 112, 112, 112;
  --ion-color-yellow500-shade: #ca8a04;
  --ion-color-yellow500-tint: #fef08a;

  --yellow400: #fde047;
  --yellow400-rgb: 253, 224, 71;
  --ion-color-yellow400: #fde047;
  --ion-color-yellow400-rgb: 253, 224, 71;
  --ion-color-yellow400-contrast: #707070;
  --ion-color-yellow400-contrast-rgb: 112, 112, 112;
  --ion-color-yellow400-shade: #eab308;
  --ion-color-yellow400-tint: #fef9c3;

  --yellow300: #fef08a;
  --yellow300-rgb: 254, 240, 138;
  --ion-color-yellow300: #fef08a;
  --ion-color-yellow300-rgb: 254, 240, 138;
  --ion-color-yellow300-contrast: #707070;
  --ion-color-yellow300-contrast-rgb: 112, 112, 112;
  --ion-color-yellow300-shade: #facc15;
  --ion-color-yellow300-tint: #fefce8;

  --yellow200: #fef9c3;
  --yellow200-rgb: 254, 249, 195;
  --ion-color-yellow200: #fef9c3;
  --ion-color-yellow200-rgb: 254, 249, 195;
  --ion-color-yellow200-contrast: #707070;
  --ion-color-yellow200-contrast-rgb: 112, 112, 112;
  --ion-color-yellow200-shade: #fde047;
  --ion-color-yellow200-tint: #fffbeb;

  --yellow100: #fefce8;
  --yellow100-rgb: 254, 252, 232;
  --ion-color-yellow100: #fefce8;
  --ion-color-yellow100-rgb: 254, 252, 232;
  --ion-color-yellow100-contrast: #707070;
  --ion-color-yellow100-contrast-rgb: 112, 112, 112;
  --ion-color-yellow100-shade: #fef08a;
  --ion-color-yellow100-tint: #ffffff;

  /** Amber **/
  --amber950: #78350f;
  --amber950-rgb: 120, 53, 15;
  --ion-color-amber950: #78350f;
  --ion-color-amber950-rgb: 120, 53, 15;
  --ion-color-amber950-contrast: #ffffff;
  --ion-color-amber950-contrast-rgb: 255, 255, 255;
  --ion-color-amber950-shade: #5f2f10;
  --ion-color-amber950-tint: #b45309;

  --amber900: #92400e;
  --amber900-rgb: 146, 64, 14;
  --ion-color-amber900: #92400e;
  --ion-color-amber900-rgb: 146, 64, 14;
  --ion-color-amber900-contrast: #ffffff;
  --ion-color-amber900-contrast-rgb: 255, 255, 255;
  --ion-color-amber900-shade: #78350f;
  --ion-color-amber900-tint: #d97706;

  --amber800: #b45309;
  --amber800-rgb: 180, 83, 9;
  --ion-color-amber800: #b45309;
  --ion-color-amber800-rgb: 180, 83, 9;
  --ion-color-amber800-contrast: #ffffff;
  --ion-color-amber800-contrast-rgb: 255, 255, 255;
  --ion-color-amber800-shade: #78350f;
  --ion-color-amber800-tint: #f59e0b;

  --amber700: #d97706;
  --amber700-rgb: 217, 119, 6;
  --ion-color-amber700: #d97706;
  --ion-color-amber700-rgb: 217, 119, 6;
  --ion-color-amber700-contrast: #ffffff;
  --ion-color-amber700-contrast-rgb: 255, 255, 255;
  --ion-color-amber700-shade: #92400e;
  --ion-color-amber700-tint: #fbbf24;

  --amber600: #f59e0b;
  --amber600-rgb: 245, 158, 11;
  --ion-color-amber600: #f59e0b;
  --ion-color-amber600-rgb: 245, 158, 11;
  --ion-color-amber600-contrast: #707070;
  --ion-color-amber600-contrast-rgb: 112, 112, 112;
  --ion-color-amber600-shade: #b45309;
  --ion-color-amber600-tint: #fcd34d;

  --amber500: #fbbf24;
  --amber500-rgb: 251, 191, 36;
  --ion-color-amber500: #fbbf24;
  --ion-color-amber500-rgb: 251, 191, 36;
  --ion-color-amber500-contrast: #707070;
  --ion-color-amber500-contrast-rgb: 112, 112, 112;
  --ion-color-amber500-shade: #d97706;
  --ion-color-amber500-tint: #fde68a;

  --amber400: #fcd34d;
  --amber400-rgb: 252, 211, 77;
  --ion-color-amber400: #fcd34d;
  --ion-color-amber400-rgb: 252, 211, 77;
  --ion-color-amber400-contrast: #707070;
  --ion-color-amber400-contrast-rgb: 112, 112, 112;
  --ion-color-amber400-shade: #f59e0b;
  --ion-color-amber400-tint: #fef3c7;

  --amber300: #fde68a;
  --amber300-rgb: 253, 230, 138;
  --ion-color-amber300: #fde68a;
  --ion-color-amber300-rgb: 253, 230, 138;
  --ion-color-amber300-contrast: #707070;
  --ion-color-amber300-contrast-rgb: 112, 112, 112;
  --ion-color-amber300-shade: #fbbf24;
  --ion-color-amber300-tint: #fffbeb;

  --amber200: #fef3c7;
  --amber200-rgb: 254, 243, 199;
  --ion-color-amber200: #fef3c7;
  --ion-color-amber200-rgb: 254, 243, 199;
  --ion-color-amber200-contrast: #707070;
  --ion-color-amber200-contrast-rgb: 112, 112, 112;
  --ion-color-amber200-shade: #fcd34d;
  --ion-color-amber200-tint: #ffffff;

  --amber100: #fffbeb;
  --amber100-rgb: 255, 251, 235;
  --ion-color-amber100: #fffbeb;
  --ion-color-amber100-rgb: 255, 251, 235;
  --ion-color-amber100-contrast: #707070;
  --ion-color-amber100-contrast-rgb: 112, 112, 112;
  --ion-color-amber100-shade: #fde68a;
  --ion-color-amber100-tint: #ffffff;
}

.ion-color-primary900 {
  --ion-color-base: var(--ion-color-primary900);
  --ion-color-base-rgb: var(--ion-color-primary900-rgb);
  --ion-color-contrast: var(--ion-color-primary900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary900-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary900-shade);
  --ion-color-tint: var(--ion-color-primary900-tint);
}
.ion-color-primary800 {
  --ion-color-base: var(--ion-color-primary800);
  --ion-color-base-rgb: var(--ion-color-primary800-rgb);
  --ion-color-contrast: var(--ion-color-primary800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary800-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary800-shade);
  --ion-color-tint: var(--ion-color-primary800-tint);
}
.ion-color-primary700 {
  --ion-color-base: var(--ion-color-primary700);
  --ion-color-base-rgb: var(--ion-color-primary700-rgb);
  --ion-color-contrast: var(--ion-color-primary700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary700-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary700-shade);
  --ion-color-tint: var(--ion-color-primary700-tint);
}
.ion-color-primary600 {
  --ion-color-base: var(--ion-color-primary600);
  --ion-color-base-rgb: var(--ion-color-primary600-rgb);
  --ion-color-contrast: var(--ion-color-primary600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary600-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary600-shade);
  --ion-color-tint: var(--ion-color-primary600-tint);
}
.ion-color-primary400 {
  --ion-color-base: var(--ion-color-primary400);
  --ion-color-base-rgb: var(--ion-color-primary400-rgb);
  --ion-color-contrast: var(--ion-color-primary400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary400-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary400-shade);
  --ion-color-tint: var(--ion-color-primary400-tint);
}
.ion-color-primary300 {
  --ion-color-base: var(--ion-color-primary300);
  --ion-color-base-rgb: var(--ion-color-primary300-rgb);
  --ion-color-contrast: var(--ion-color-primary300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary300-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary300-shade);
  --ion-color-tint: var(--ion-color-primary300-tint);
}
.ion-color-primary200 {
  --ion-color-base: var(--ion-color-primary200);
  --ion-color-base-rgb: var(--ion-color-primary200-rgb);
  --ion-color-contrast: var(--ion-color-primary200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary200-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary200-shade);
  --ion-color-tint: var(--ion-color-primary200-tint);
}
.ion-color-primary100 {
  --ion-color-base: var(--ion-color-primary100);
  --ion-color-base-rgb: var(--ion-color-primary100-rgb);
  --ion-color-contrast: var(--ion-color-primary100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary100-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary100-shade);
  --ion-color-tint: var(--ion-color-primary100-tint);
}

.ion-color-secondary900 {
  --ion-color-base: var(--ion-color-secondary900);
  --ion-color-base-rgb: var(--ion-color-secondary900-rgb);
  --ion-color-contrast: var(--ion-color-secondary900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary900-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary900-shade);
  --ion-color-tint: var(--ion-color-secondary900-tint);
}
.ion-color-secondary800 {
  --ion-color-base: var(--ion-color-secondary800);
  --ion-color-base-rgb: var(--ion-color-secondary800-rgb);
  --ion-color-contrast: var(--ion-color-secondary800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary800-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary800-shade);
  --ion-color-tint: var(--ion-color-secondary800-tint);
}
.ion-color-secondary700 {
  --ion-color-base: var(--ion-color-secondary700);
  --ion-color-base-rgb: var(--ion-color-secondary700-rgb);
  --ion-color-contrast: var(--ion-color-secondary700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary700-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary700-shade);
  --ion-color-tint: var(--ion-color-secondary700-tint);
}
.ion-color-secondary600 {
  --ion-color-base: var(--ion-color-secondary600);
  --ion-color-base-rgb: var(--ion-color-secondary600-rgb);
  --ion-color-contrast: var(--ion-color-secondary600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary600-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary600-shade);
  --ion-color-tint: var(--ion-color-secondary600-tint);
}
.ion-color-secondary400 {
  --ion-color-base: var(--ion-color-secondary400);
  --ion-color-base-rgb: var(--ion-color-secondary400-rgb);
  --ion-color-contrast: var(--ion-color-secondary400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary400-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary400-shade);
  --ion-color-tint: var(--ion-color-secondary400-tint);
}
.ion-color-secondary300 {
  --ion-color-base: var(--ion-color-secondary300);
  --ion-color-base-rgb: var(--ion-color-secondary300-rgb);
  --ion-color-contrast: var(--ion-color-secondary300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary300-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary300-shade);
  --ion-color-tint: var(--ion-color-secondary300-tint);
}
.ion-color-secondary200 {
  --ion-color-base: var(--ion-color-secondary200);
  --ion-color-base-rgb: var(--ion-color-secondary200-rgb);
  --ion-color-contrast: var(--ion-color-secondary200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary200-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary200-shade);
  --ion-color-tint: var(--ion-color-secondary200-tint);
}
.ion-color-secondary100 {
  --ion-color-base: var(--ion-color-secondary100);
  --ion-color-base-rgb: var(--ion-color-secondary100-rgb);
  --ion-color-contrast: var(--ion-color-secondary100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary100-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary100-shade);
  --ion-color-tint: var(--ion-color-secondary100-tint);
}

.ion-color-tertiary900 {
  --ion-color-base: var(--ion-color-tertiary900);
  --ion-color-base-rgb: var(--ion-color-tertiary900-rgb);
  --ion-color-contrast: var(--ion-color-tertiary900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary900-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary900-shade);
  --ion-color-tint: var(--ion-color-tertiary900-tint);
}
.ion-color-tertiary800 {
  --ion-color-base: var(--ion-color-tertiary800);
  --ion-color-base-rgb: var(--ion-color-tertiary800-rgb);
  --ion-color-contrast: var(--ion-color-tertiary800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary800-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary800-shade);
  --ion-color-tint: var(--ion-color-tertiary800-tint);
}
.ion-color-tertiary700 {
  --ion-color-base: var(--ion-color-tertiary700);
  --ion-color-base-rgb: var(--ion-color-tertiary700-rgb);
  --ion-color-contrast: var(--ion-color-tertiary700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary700-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary700-shade);
  --ion-color-tint: var(--ion-color-tertiary700-tint);
}
.ion-color-tertiary600 {
  --ion-color-base: var(--ion-color-tertiary600);
  --ion-color-base-rgb: var(--ion-color-tertiary600-rgb);
  --ion-color-contrast: var(--ion-color-tertiary600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary600-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary600-shade);
  --ion-color-tint: var(--ion-color-tertiary600-tint);
}
.ion-color-tertiary400 {
  --ion-color-base: var(--ion-color-tertiary400);
  --ion-color-base-rgb: var(--ion-color-tertiary400-rgb);
  --ion-color-contrast: var(--ion-color-tertiary400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary400-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary400-shade);
  --ion-color-tint: var(--ion-color-tertiary400-tint);
}
.ion-color-tertiary300 {
  --ion-color-base: var(--ion-color-tertiary300);
  --ion-color-base-rgb: var(--ion-color-tertiary300-rgb);
  --ion-color-contrast: var(--ion-color-tertiary300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary300-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary300-shade);
  --ion-color-tint: var(--ion-color-tertiary300-tint);
}
.ion-color-tertiary200 {
  --ion-color-base: var(--ion-color-tertiary200);
  --ion-color-base-rgb: var(--ion-color-tertiary200-rgb);
  --ion-color-contrast: var(--ion-color-tertiary200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary200-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary200-shade);
  --ion-color-tint: var(--ion-color-tertiary200-tint);
}
.ion-color-tertiary100 {
  --ion-color-base: var(--ion-color-tertiary100);
  --ion-color-base-rgb: var(--ion-color-tertiary100-rgb);
  --ion-color-contrast: var(--ion-color-tertiary100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-tertiary100-contrast-rgb);
  --ion-color-shade: var(--ion-color-tertiary100-shade);
  --ion-color-tint: var(--ion-color-tertiary100-tint);
}

.ion-color-quaternary900 {
  --ion-color-base: var(--ion-color-quaternary900);
  --ion-color-base-rgb: var(--ion-color-quaternary900-rgb);
  --ion-color-contrast: var(--ion-color-quaternary900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quaternary900-contrast-rgb);
  --ion-color-shade: var(--ion-color-quaternary900-shade);
  --ion-color-tint: var(--ion-color-quaternary900-tint);
}
.ion-color-quaternary800 {
  --ion-color-base: var(--ion-color-quaternary800);
  --ion-color-base-rgb: var(--ion-color-quaternary800-rgb);
  --ion-color-contrast: var(--ion-color-quaternary800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quaternary800-contrast-rgb);
  --ion-color-shade: var(--ion-color-quaternary800-shade);
  --ion-color-tint: var(--ion-color-quaternary800-tint);
}
.ion-color-quaternary700 {
  --ion-color-base: var(--ion-color-quaternary700);
  --ion-color-base-rgb: var(--ion-color-quaternary700-rgb);
  --ion-color-contrast: var(--ion-color-quaternary700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quaternary700-contrast-rgb);
  --ion-color-shade: var(--ion-color-quaternary700-shade);
  --ion-color-tint: var(--ion-color-quaternary700-tint);
}
.ion-color-quaternary600 {
  --ion-color-base: var(--ion-color-quaternary600);
  --ion-color-base-rgb: var(--ion-color-quaternary600-rgb);
  --ion-color-contrast: var(--ion-color-quaternary600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quaternary600-contrast-rgb);
  --ion-color-shade: var(--ion-color-quaternary600-shade);
  --ion-color-tint: var(--ion-color-quaternary600-tint);
}
.ion-color-quaternary500 {
  --ion-color-base: var(--ion-color-quaternary500);
  --ion-color-base-rgb: var(--ion-color-quaternary500-rgb);
  --ion-color-contrast: var(--ion-color-quaternary500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quaternary500-contrast-rgb);
  --ion-color-shade: var(--ion-color-quaternary500-shade);
  --ion-color-tint: var(--ion-color-quaternary500-tint);
}
.ion-color-quaternary400 {
  --ion-color-base: var(--ion-color-quaternary400);
  --ion-color-base-rgb: var(--ion-color-quaternary400-rgb);
  --ion-color-contrast: var(--ion-color-quaternary400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quaternary400-contrast-rgb);
  --ion-color-shade: var(--ion-color-quaternary400-shade);
  --ion-color-tint: var(--ion-color-quaternary400-tint);
}
.ion-color-quaternary300 {
  --ion-color-base: var(--ion-color-quaternary300);
  --ion-color-base-rgb: var(--ion-color-quaternary300-rgb);
  --ion-color-contrast: var(--ion-color-quaternary300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quaternary300-contrast-rgb);
  --ion-color-shade: var(--ion-color-quaternary300-shade);
  --ion-color-tint: var(--ion-color-quaternary300-tint);
}
.ion-color-quaternary200 {
  --ion-color-base: var(--ion-color-quaternary200);
  --ion-color-base-rgb: var(--ion-color-quaternary200-rgb);
  --ion-color-contrast: var(--ion-color-quaternary200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quaternary200-contrast-rgb);
  --ion-color-shade: var(--ion-color-quaternary200-shade);
  --ion-color-tint: var(--ion-color-quaternary200-tint);
}
.ion-color-quaternary100 {
  --ion-color-base: var(--ion-color-quaternary100);
  --ion-color-base-rgb: var(--ion-color-quaternary100-rgb);
  --ion-color-contrast: var(--ion-color-quaternary100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-quaternary100-contrast-rgb);
  --ion-color-shade: var(--ion-color-quaternary100-shade);
  --ion-color-tint: var(--ion-color-quaternary100-tint);
}

.ion-color-primary-grey900 {
  --ion-color-base: var(--ion-color-primary-grey900);
  --ion-color-base-rgb: var(--ion-color-primary-grey900-rgb);
  --ion-color-contrast: var(--ion-color-primary-grey900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-grey900-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-grey900-shade);
  --ion-color-tint: var(--ion-color-primary-grey900-tint);
}
.ion-color-primary-grey800 {
  --ion-color-base: var(--ion-color-primary-grey800);
  --ion-color-base-rgb: var(--ion-color-primary-grey800-rgb);
  --ion-color-contrast: var(--ion-color-primary-grey800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-grey800-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-grey800-shade);
  --ion-color-tint: var(--ion-color-primary-grey800-tint);
}
.ion-color-primary-grey700 {
  --ion-color-base: var(--ion-color-primary-grey700);
  --ion-color-base-rgb: var(--ion-color-primary-grey700-rgb);
  --ion-color-contrast: var(--ion-color-primary-grey700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-grey700-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-grey700-shade);
  --ion-color-tint: var(--ion-color-primary-grey700-tint);
}
.ion-color-primary-grey600 {
  --ion-color-base: var(--ion-color-primary-grey600);
  --ion-color-base-rgb: var(--ion-color-primary-grey600-rgb);
  --ion-color-contrast: var(--ion-color-primary-grey600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-grey600-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-grey600-shade);
  --ion-color-tint: var(--ion-color-primary-grey600-tint);
}
.ion-color-primary-grey500 {
  --ion-color-base: var(--ion-color-primary-grey500);
  --ion-color-base-rgb: var(--ion-color-primary-grey500-rgb);
  --ion-color-contrast: var(--ion-color-primary-grey500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-grey500-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-grey500-shade);
  --ion-color-tint: var(--ion-color-primary-grey500-tint);
}
.ion-color-primary-grey400 {
  --ion-color-base: var(--ion-color-primary-grey400);
  --ion-color-base-rgb: var(--ion-color-primary-grey400-rgb);
  --ion-color-contrast: var(--ion-color-primary-grey400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-grey400-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-grey400-shade);
  --ion-color-tint: var(--ion-color-primary-grey400-tint);
}
.ion-color-primary-grey300 {
  --ion-color-base: var(--ion-color-primary-grey300);
  --ion-color-base-rgb: var(--ion-color-primary-grey300-rgb);
  --ion-color-contrast: var(--ion-color-primary-grey300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-grey300-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-grey300-shade);
  --ion-color-tint: var(--ion-color-primary-grey300-tint);
}
.ion-color-primary-grey200 {
  --ion-color-base: var(--ion-color-primary-grey200);
  --ion-color-base-rgb: var(--ion-color-primary-grey200-rgb);
  --ion-color-contrast: var(--ion-color-primary-grey200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-grey200-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-grey200-shade);
  --ion-color-tint: var(--ion-color-primary-grey200-tint);
}
.ion-color-primary-grey100 {
  --ion-color-base: var(--ion-color-primary-grey100);
  --ion-color-base-rgb: var(--ion-color-primary-grey100-rgb);
  --ion-color-contrast: var(--ion-color-primary-grey100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-primary-grey100-contrast-rgb);
  --ion-color-shade: var(--ion-color-primary-grey100-shade);
  --ion-color-tint: var(--ion-color-primary-grey100-tint);
}

.ion-color-succes950 {
  --ion-color-base: var(--ion-color-succes950);
  --ion-color-base-rgb: var(--ion-color-succes950-rgb);
  --ion-color-contrast: var(--ion-color-succes950-contrast);
  --ion-color-contrast-rgb: var(--ion-color-succes950-contrast-rgb);
  --ion-color-shade: var(--ion-color-succes950-shade);
  --ion-color-tint: var(--ion-color-succes950-tint);
}
.ion-color-succes900 {
  --ion-color-base: var(--ion-color-succes900);
  --ion-color-base-rgb: var(--ion-color-succes900-rgb);
  --ion-color-contrast: var(--ion-color-succes900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-succes900-contrast-rgb);
  --ion-color-shade: var(--ion-color-succes900-shade);
  --ion-color-tint: var(--ion-color-succes900-tint);
}
.ion-color-succes800 {
  --ion-color-base: var(--ion-color-succes800);
  --ion-color-base-rgb: var(--ion-color-succes800-rgb);
  --ion-color-contrast: var(--ion-color-succes800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-succes800-contrast-rgb);
  --ion-color-shade: var(--ion-color-succes800-shade);
  --ion-color-tint: var(--ion-color-succes800-tint);
}
.ion-color-succes700 {
  --ion-color-base: var(--ion-color-succes700);
  --ion-color-base-rgb: var(--ion-color-succes700-rgb);
  --ion-color-contrast: var(--ion-color-succes700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-succes700-contrast-rgb);
  --ion-color-shade: var(--ion-color-succes700-shade);
  --ion-color-tint: var(--ion-color-succes700-tint);
}
.ion-color-succes600 {
  --ion-color-base: var(--ion-color-succes600);
  --ion-color-base-rgb: var(--ion-color-succes600-rgb);
  --ion-color-contrast: var(--ion-color-succes600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-succes600-contrast-rgb);
  --ion-color-shade: var(--ion-color-succes600-shade);
  --ion-color-tint: var(--ion-color-succes600-tint);
}
.ion-color-succes500 {
  --ion-color-base: var(--ion-color-succes500);
  --ion-color-base-rgb: var(--ion-color-succes500-rgb);
  --ion-color-contrast: var(--ion-color-succes500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-succes500-contrast-rgb);
  --ion-color-shade: var(--ion-color-succes500-shade);
  --ion-color-tint: var(--ion-color-succes500-tint);
}
.ion-color-succes400 {
  --ion-color-base: var(--ion-color-succes400);
  --ion-color-base-rgb: var(--ion-color-succes400-rgb);
  --ion-color-contrast: var(--ion-color-succes400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-succes400-contrast-rgb);
  --ion-color-shade: var(--ion-color-succes400-shade);
  --ion-color-tint: var(--ion-color-succes400-tint);
}
.ion-color-succes300 {
  --ion-color-base: var(--ion-color-succes300);
  --ion-color-base-rgb: var(--ion-color-succes300-rgb);
  --ion-color-contrast: var(--ion-color-succes300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-succes300-contrast-rgb);
  --ion-color-shade: var(--ion-color-succes300-shade);
  --ion-color-tint: var(--ion-color-succes300-tint);
}
.ion-color-succes200 {
  --ion-color-base: var(--ion-color-succes200);
  --ion-color-base-rgb: var(--ion-color-succes200-rgb);
  --ion-color-contrast: var(--ion-color-succes200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-succes200-contrast-rgb);
  --ion-color-shade: var(--ion-color-succes200-shade);
  --ion-color-tint: var(--ion-color-succes200-tint);
}
.ion-color-succes100 {
  --ion-color-base: var(--ion-color-succes100);
  --ion-color-base-rgb: var(--ion-color-succes100-rgb);
  --ion-color-contrast: var(--ion-color-succes100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-succes100-contrast-rgb);
  --ion-color-shade: var(--ion-color-succes100-shade);
  --ion-color-tint: var(--ion-color-succes100-tint);
}

.ion-color-warning950 {
  --ion-color-base: var(--ion-color-warning950);
  --ion-color-base-rgb: var(--ion-color-warning950-rgb);
  --ion-color-contrast: var(--ion-color-warning950-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning950-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning950-shade);
  --ion-color-tint: var(--ion-color-warning950-tint);
}
.ion-color-warning900 {
  --ion-color-base: var(--ion-color-warning900);
  --ion-color-base-rgb: var(--ion-color-warning900-rgb);
  --ion-color-contrast: var(--ion-color-warning900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning900-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning900-shade);
  --ion-color-tint: var(--ion-color-warning900-tint);
}
.ion-color-warning800 {
  --ion-color-base: var(--ion-color-warning800);
  --ion-color-base-rgb: var(--ion-color-warning800-rgb);
  --ion-color-contrast: var(--ion-color-warning800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning800-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning800-shade);
  --ion-color-tint: var(--ion-color-warning800-tint);
}
.ion-color-warning700 {
  --ion-color-base: var(--ion-color-warning700);
  --ion-color-base-rgb: var(--ion-color-warning700-rgb);
  --ion-color-contrast: var(--ion-color-warning700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning700-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning700-shade);
  --ion-color-tint: var(--ion-color-warning700-tint);
}
.ion-color-warning600 {
  --ion-color-base: var(--ion-color-warning600);
  --ion-color-base-rgb: var(--ion-color-warning600-rgb);
  --ion-color-contrast: var(--ion-color-warning600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning600-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning600-shade);
  --ion-color-tint: var(--ion-color-warning600-tint);
}
.ion-color-warning500 {
  --ion-color-base: var(--ion-color-warning500);
  --ion-color-base-rgb: var(--ion-color-warning500-rgb);
  --ion-color-contrast: var(--ion-color-warning500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning500-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning500-shade);
  --ion-color-tint: var(--ion-color-warning500-tint);
}
.ion-color-warning400 {
  --ion-color-base: var(--ion-color-warning400);
  --ion-color-base-rgb: var(--ion-color-warning400-rgb);
  --ion-color-contrast: var(--ion-color-warning400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning400-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning400-shade);
  --ion-color-tint: var(--ion-color-warning400-tint);
}
.ion-color-warning300 {
  --ion-color-base: var(--ion-color-warning300);
  --ion-color-base-rgb: var(--ion-color-warning300-rgb);
  --ion-color-contrast: var(--ion-color-warning300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning300-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning300-shade);
  --ion-color-tint: var(--ion-color-warning300-tint);
}
.ion-color-warning200 {
  --ion-color-base: var(--ion-color-warning200);
  --ion-color-base-rgb: var(--ion-color-warning200-rgb);
  --ion-color-contrast: var(--ion-color-warning200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning200-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning200-shade);
  --ion-color-tint: var(--ion-color-warning200-tint);
}
.ion-color-warning100 {
  --ion-color-base: var(--ion-color-warning100);
  --ion-color-base-rgb: var(--ion-color-warning100-rgb);
  --ion-color-contrast: var(--ion-color-warning100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning100-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning100-shade);
  --ion-color-tint: var(--ion-color-warning100-tint);
}

.ion-color-error950 {
  --ion-color-base: var(--ion-color-error950);
  --ion-color-base-rgb: var(--ion-color-error950-rgb);
  --ion-color-contrast: var(--ion-color-error950-contrast);
  --ion-color-contrast-rgb: var(--ion-color-error950-contrast-rgb);
  --ion-color-shade: var(--ion-color-error950-shade);
  --ion-color-tint: var(--ion-color-error950-tint);
}
.ion-color-error900 {
  --ion-color-base: var(--ion-color-error900);
  --ion-color-base-rgb: var(--ion-color-error900-rgb);
  --ion-color-contrast: var(--ion-color-error900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-error900-contrast-rgb);
  --ion-color-shade: var(--ion-color-error900-shade);
  --ion-color-tint: var(--ion-color-error900-tint);
}
.ion-color-error800 {
  --ion-color-base: var(--ion-color-error800);
  --ion-color-base-rgb: var(--ion-color-error800-rgb);
  --ion-color-contrast: var(--ion-color-error800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-error800-contrast-rgb);
  --ion-color-shade: var(--ion-color-error800-shade);
  --ion-color-tint: var(--ion-color-error800-tint);
}
.ion-color-error700 {
  --ion-color-base: var(--ion-color-error700);
  --ion-color-base-rgb: var(--ion-color-error700-rgb);
  --ion-color-contrast: var(--ion-color-error700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-error700-contrast-rgb);
  --ion-color-shade: var(--ion-color-error700-shade);
  --ion-color-tint: var(--ion-color-error700-tint);
}
.ion-color-error600 {
  --ion-color-base: var(--ion-color-error600);
  --ion-color-base-rgb: var(--ion-color-error600-rgb);
  --ion-color-contrast: var(--ion-color-error600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-error600-contrast-rgb);
  --ion-color-shade: var(--ion-color-error600-shade);
  --ion-color-tint: var(--ion-color-error600-tint);
}
.ion-color-error500 {
  --ion-color-base: var(--ion-color-error500);
  --ion-color-base-rgb: var(--ion-color-error500-rgb);
  --ion-color-contrast: var(--ion-color-error500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-error500-contrast-rgb);
  --ion-color-shade: var(--ion-color-error500-shade);
  --ion-color-tint: var(--ion-color-error500-tint);
}
.ion-color-error400 {
  --ion-color-base: var(--ion-color-error400);
  --ion-color-base-rgb: var(--ion-color-error400-rgb);
  --ion-color-contrast: var(--ion-color-error400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-error400-contrast-rgb);
  --ion-color-shade: var(--ion-color-error400-shade);
  --ion-color-tint: var(--ion-color-error400-tint);
}
.ion-color-error300 {
  --ion-color-base: var(--ion-color-error300);
  --ion-color-base-rgb: var(--ion-color-error300-rgb);
  --ion-color-contrast: var(--ion-color-error300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-error300-contrast-rgb);
  --ion-color-shade: var(--ion-color-error300-shade);
  --ion-color-tint: var(--ion-color-error300-tint);
}
.ion-color-error200 {
  --ion-color-base: var(--ion-color-error200);
  --ion-color-base-rgb: var(--ion-color-error200-rgb);
  --ion-color-contrast: var(--ion-color-error200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-error200-contrast-rgb);
  --ion-color-shade: var(--ion-color-error200-shade);
  --ion-color-tint: var(--ion-color-error200-tint);
}
.ion-color-error100 {
  --ion-color-base: var(--ion-color-error100);
  --ion-color-base-rgb: var(--ion-color-error100-rgb);
  --ion-color-contrast: var(--ion-color-error100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-error100-contrast-rgb);
  --ion-color-shade: var(--ion-color-error100-shade);
  --ion-color-tint: var(--ion-color-error100-tint);
}

.ion-color-teal950 {
  --ion-color-base: var(--ion-color-teal950);
  --ion-color-base-rgb: var(--ion-color-teal950-rgb);
  --ion-color-contrast: var(--ion-color-teal950-contrast);
  --ion-color-contrast-rgb: var(--ion-color-teal950-contrast-rgb);
  --ion-color-shade: var(--ion-color-teal950-shade);
  --ion-color-tint: var(--ion-color-teal950-tint);
}
.ion-color-teal900 {
  --ion-color-base: var(--ion-color-teal900);
  --ion-color-base-rgb: var(--ion-color-teal900-rgb);
  --ion-color-contrast: var(--ion-color-teal900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-teal900-contrast-rgb);
  --ion-color-shade: var(--ion-color-teal900-shade);
  --ion-color-tint: var(--ion-color-teal900-tint);
}
.ion-color-teal800 {
  --ion-color-base: var(--ion-color-teal800);
  --ion-color-base-rgb: var(--ion-color-teal800-rgb);
  --ion-color-contrast: var(--ion-color-teal800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-teal800-contrast-rgb);
  --ion-color-shade: var(--ion-color-teal800-shade);
  --ion-color-tint: var(--ion-color-teal800-tint);
}
.ion-color-teal700 {
  --ion-color-base: var(--ion-color-teal700);
  --ion-color-base-rgb: var(--ion-color-teal700-rgb);
  --ion-color-contrast: var(--ion-color-teal700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-teal700-contrast-rgb);
  --ion-color-shade: var(--ion-color-teal700-shade);
  --ion-color-tint: var(--ion-color-teal700-tint);
}
.ion-color-teal600 {
  --ion-color-base: var(--ion-color-teal600);
  --ion-color-base-rgb: var(--ion-color-teal600-rgb);
  --ion-color-contrast: var(--ion-color-teal600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-teal600-contrast-rgb);
  --ion-color-shade: var(--ion-color-teal600-shade);
  --ion-color-tint: var(--ion-color-teal600-tint);
}
.ion-color-teal500 {
  --ion-color-base: var(--ion-color-teal500);
  --ion-color-base-rgb: var(--ion-color-teal500-rgb);
  --ion-color-contrast: var(--ion-color-teal500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-teal500-contrast-rgb);
  --ion-color-shade: var(--ion-color-teal500-shade);
  --ion-color-tint: var(--ion-color-teal500-tint);
}
.ion-color-teal400 {
  --ion-color-base: var(--ion-color-teal400);
  --ion-color-base-rgb: var(--ion-color-teal400-rgb);
  --ion-color-contrast: var(--ion-color-teal400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-teal400-contrast-rgb);
  --ion-color-shade: var(--ion-color-teal400-shade);
  --ion-color-tint: var(--ion-color-teal400-tint);
}
.ion-color-teal300 {
  --ion-color-base: var(--ion-color-teal300);
  --ion-color-base-rgb: var(--ion-color-teal300-rgb);
  --ion-color-contrast: var(--ion-color-teal300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-teal300-contrast-rgb);
  --ion-color-shade: var(--ion-color-teal300-shade);
  --ion-color-tint: var(--ion-color-teal300-tint);
}
.ion-color-teal200 {
  --ion-color-base: var(--ion-color-teal200);
  --ion-color-base-rgb: var(--ion-color-teal200-rgb);
  --ion-color-contrast: var(--ion-color-teal200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-teal200-contrast-rgb);
  --ion-color-shade: var(--ion-color-teal200-shade);
  --ion-color-tint: var(--ion-color-teal200-tint);
}
.ion-color-teal100 {
  --ion-color-base: var(--ion-color-teal100);
  --ion-color-base-rgb: var(--ion-color-teal100-rgb);
  --ion-color-contrast: var(--ion-color-teal100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-teal100-contrast-rgb);
  --ion-color-shade: var(--ion-color-teal100-shade);
  --ion-color-tint: var(--ion-color-teal100-tint);
}

.ion-color-lime950 {
  --ion-color-base: var(--ion-color-lime950);
  --ion-color-base-rgb: var(--ion-color-lime950-rgb);
  --ion-color-contrast: var(--ion-color-lime950-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lime950-contrast-rgb);
  --ion-color-shade: var(--ion-color-lime950-shade);
  --ion-color-tint: var(--ion-color-lime950-tint);
}
.ion-color-lime900 {
  --ion-color-base: var(--ion-color-lime900);
  --ion-color-base-rgb: var(--ion-color-lime900-rgb);
  --ion-color-contrast: var(--ion-color-lime900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lime900-contrast-rgb);
  --ion-color-shade: var(--ion-color-lime900-shade);
  --ion-color-tint: var(--ion-color-lime900-tint);
}
.ion-color-lime800 {
  --ion-color-base: var(--ion-color-lime800);
  --ion-color-base-rgb: var(--ion-color-lime800-rgb);
  --ion-color-contrast: var(--ion-color-lime800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lime800-contrast-rgb);
  --ion-color-shade: var(--ion-color-lime800-shade);
  --ion-color-tint: var(--ion-color-lime800-tint);
}
.ion-color-lime700 {
  --ion-color-base: var(--ion-color-lime700);
  --ion-color-base-rgb: var(--ion-color-lime700-rgb);
  --ion-color-contrast: var(--ion-color-lime700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lime700-contrast-rgb);
  --ion-color-shade: var(--ion-color-lime700-shade);
  --ion-color-tint: var(--ion-color-lime700-tint);
}
.ion-color-lime600 {
  --ion-color-base: var(--ion-color-lime600);
  --ion-color-base-rgb: var(--ion-color-lime600-rgb);
  --ion-color-contrast: var(--ion-color-lime600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lime600-contrast-rgb);
  --ion-color-shade: var(--ion-color-lime600-shade);
  --ion-color-tint: var(--ion-color-lime600-tint);
}
.ion-color-lime500 {
  --ion-color-base: var(--ion-color-lime500);
  --ion-color-base-rgb: var(--ion-color-lime500-rgb);
  --ion-color-contrast: var(--ion-color-lime500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lime500-contrast-rgb);
  --ion-color-shade: var(--ion-color-lime500-shade);
  --ion-color-tint: var(--ion-color-lime500-tint);
}
.ion-color-lime400 {
  --ion-color-base: var(--ion-color-lime400);
  --ion-color-base-rgb: var(--ion-color-lime400-rgb);
  --ion-color-contrast: var(--ion-color-lime400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lime400-contrast-rgb);
  --ion-color-shade: var(--ion-color-lime400-shade);
  --ion-color-tint: var(--ion-color-lime400-tint);
}
.ion-color-lime300 {
  --ion-color-base: var(--ion-color-lime300);
  --ion-color-base-rgb: var(--ion-color-lime300-rgb);
  --ion-color-contrast: var(--ion-color-lime300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lime300-contrast-rgb);
  --ion-color-shade: var(--ion-color-lime300-shade);
  --ion-color-tint: var(--ion-color-lime300-tint);
}
.ion-color-lime200 {
  --ion-color-base: var(--ion-color-lime200);
  --ion-color-base-rgb: var(--ion-color-lime200-rgb);
  --ion-color-contrast: var(--ion-color-lime200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lime200-contrast-rgb);
  --ion-color-shade: var(--ion-color-lime200-shade);
  --ion-color-tint: var(--ion-color-lime200-tint);
}
.ion-color-lime100 {
  --ion-color-base: var(--ion-color-lime100);
  --ion-color-base-rgb: var(--ion-color-lime100-rgb);
  --ion-color-contrast: var(--ion-color-lime100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lime100-contrast-rgb);
  --ion-color-shade: var(--ion-color-lime100-shade);
  --ion-color-tint: var(--ion-color-lime100-tint);
}

.ion-color-pink950 {
  --ion-color-base: var(--ion-color-pink950);
  --ion-color-base-rgb: var(--ion-color-pink950-rgb);
  --ion-color-contrast: var(--ion-color-pink950-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink950-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink950-shade);
  --ion-color-tint: var(--ion-color-pink950-tint);
}
.ion-color-pink900 {
  --ion-color-base: var(--ion-color-pink900);
  --ion-color-base-rgb: var(--ion-color-pink900-rgb);
  --ion-color-contrast: var(--ion-color-pink900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink900-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink900-shade);
  --ion-color-tint: var(--ion-color-pink900-tint);
}
.ion-color-pink800 {
  --ion-color-base: var(--ion-color-pink800);
  --ion-color-base-rgb: var(--ion-color-pink800-rgb);
  --ion-color-contrast: var(--ion-color-pink800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink800-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink800-shade);
  --ion-color-tint: var(--ion-color-pink800-tint);
}
.ion-color-pink700 {
  --ion-color-base: var(--ion-color-pink700);
  --ion-color-base-rgb: var(--ion-color-pink700-rgb);
  --ion-color-contrast: var(--ion-color-pink700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink700-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink700-shade);
  --ion-color-tint: var(--ion-color-pink700-tint);
}
.ion-color-pink600 {
  --ion-color-base: var(--ion-color-pink600);
  --ion-color-base-rgb: var(--ion-color-pink600-rgb);
  --ion-color-contrast: var(--ion-color-pink600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink600-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink600-shade);
  --ion-color-tint: var(--ion-color-pink600-tint);
}
.ion-color-pink500 {
  --ion-color-base: var(--ion-color-pink500);
  --ion-color-base-rgb: var(--ion-color-pink500-rgb);
  --ion-color-contrast: var(--ion-color-pink500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink500-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink500-shade);
  --ion-color-tint: var(--ion-color-pink500-tint);
}
.ion-color-pink400 {
  --ion-color-base: var(--ion-color-pink400);
  --ion-color-base-rgb: var(--ion-color-pink400-rgb);
  --ion-color-contrast: var(--ion-color-pink400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink400-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink400-shade);
  --ion-color-tint: var(--ion-color-pink400-tint);
}
.ion-color-pink300 {
  --ion-color-base: var(--ion-color-pink300);
  --ion-color-base-rgb: var(--ion-color-pink300-rgb);
  --ion-color-contrast: var(--ion-color-pink300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink300-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink300-shade);
  --ion-color-tint: var(--ion-color-pink300-tint);
}
.ion-color-pink200 {
  --ion-color-base: var(--ion-color-pink200);
  --ion-color-base-rgb: var(--ion-color-pink200-rgb);
  --ion-color-contrast: var(--ion-color-pink200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink200-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink200-shade);
  --ion-color-tint: var(--ion-color-pink200-tint);
}
.ion-color-pink100 {
  --ion-color-base: var(--ion-color-pink100);
  --ion-color-base-rgb: var(--ion-color-pink100-rgb);
  --ion-color-contrast: var(--ion-color-pink100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink100-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink100-shade);
  --ion-color-tint: var(--ion-color-pink100-tint);
}

.ion-color-fuchsia950 {
  --ion-color-base: var(--ion-color-fuchsia950);
  --ion-color-base-rgb: var(--ion-color-fuchsia950-rgb);
  --ion-color-contrast: var(--ion-color-fuchsia950-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fuchsia950-contrast-rgb);
  --ion-color-shade: var(--ion-color-fuchsia950-shade);
  --ion-color-tint: var(--ion-color-fuchsia950-tint);
}
.ion-color-fuchsia900 {
  --ion-color-base: var(--ion-color-fuchsia900);
  --ion-color-base-rgb: var(--ion-color-fuchsia900-rgb);
  --ion-color-contrast: var(--ion-color-fuchsia900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fuchsia900-contrast-rgb);
  --ion-color-shade: var(--ion-color-fuchsia900-shade);
  --ion-color-tint: var(--ion-color-fuchsia900-tint);
}
.ion-color-fuchsia800 {
  --ion-color-base: var(--ion-color-fuchsia800);
  --ion-color-base-rgb: var(--ion-color-fuchsia800-rgb);
  --ion-color-contrast: var(--ion-color-fuchsia800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fuchsia800-contrast-rgb);
  --ion-color-shade: var(--ion-color-fuchsia800-shade);
  --ion-color-tint: var(--ion-color-fuchsia800-tint);
}
.ion-color-fuchsia700 {
  --ion-color-base: var(--ion-color-fuchsia700);
  --ion-color-base-rgb: var(--ion-color-fuchsia700-rgb);
  --ion-color-contrast: var(--ion-color-fuchsia700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fuchsia700-contrast-rgb);
  --ion-color-shade: var(--ion-color-fuchsia700-shade);
  --ion-color-tint: var(--ion-color-fuchsia700-tint);
}
.ion-color-fuchsia600 {
  --ion-color-base: var(--ion-color-fuchsia600);
  --ion-color-base-rgb: var(--ion-color-fuchsia600-rgb);
  --ion-color-contrast: var(--ion-color-fuchsia600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fuchsia600-contrast-rgb);
  --ion-color-shade: var(--ion-color-fuchsia600-shade);
  --ion-color-tint: var(--ion-color-fuchsia600-tint);
}
.ion-color-fuchsia500 {
  --ion-color-base: var(--ion-color-fuchsia500);
  --ion-color-base-rgb: var(--ion-color-fuchsia500-rgb);
  --ion-color-contrast: var(--ion-color-fuchsia500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fuchsia500-contrast-rgb);
  --ion-color-shade: var(--ion-color-fuchsia500-shade);
  --ion-color-tint: var(--ion-color-fuchsia500-tint);
}
.ion-color-fuchsia400 {
  --ion-color-base: var(--ion-color-fuchsia400);
  --ion-color-base-rgb: var(--ion-color-fuchsia400-rgb);
  --ion-color-contrast: var(--ion-color-fuchsia400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fuchsia400-contrast-rgb);
  --ion-color-shade: var(--ion-color-fuchsia400-shade);
  --ion-color-tint: var(--ion-color-fuchsia400-tint);
}
.ion-color-fuchsia300 {
  --ion-color-base: var(--ion-color-fuchsia300);
  --ion-color-base-rgb: var(--ion-color-fuchsia300-rgb);
  --ion-color-contrast: var(--ion-color-fuchsia300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fuchsia300-contrast-rgb);
  --ion-color-shade: var(--ion-color-fuchsia300-shade);
  --ion-color-tint: var(--ion-color-fuchsia300-tint);
}
.ion-color-fuchsia200 {
  --ion-color-base: var(--ion-color-fuchsia200);
  --ion-color-base-rgb: var(--ion-color-fuchsia200-rgb);
  --ion-color-contrast: var(--ion-color-fuchsia200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fuchsia200-contrast-rgb);
  --ion-color-shade: var(--ion-color-fuchsia200-shade);
  --ion-color-tint: var(--ion-color-fuchsia200-tint);
}
.ion-color-fuchsia100 {
  --ion-color-base: var(--ion-color-fuchsia100);
  --ion-color-base-rgb: var(--ion-color-fuchsia100-rgb);
  --ion-color-contrast: var(--ion-color-fuchsia100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-fuchsia100-contrast-rgb);
  --ion-color-shade: var(--ion-color-fuchsia100-shade);
  --ion-color-tint: var(--ion-color-fuchsia100-tint);
}

.ion-color-purple950 {
  --ion-color-base: var(--ion-color-purple950);
  --ion-color-base-rgb: var(--ion-color-purple950-rgb);
  --ion-color-contrast: var(--ion-color-purple950-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple950-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple950-shade);
  --ion-color-tint: var(--ion-color-purple950-tint);
}
.ion-color-purple900 {
  --ion-color-base: var(--ion-color-purple900);
  --ion-color-base-rgb: var(--ion-color-purple900-rgb);
  --ion-color-contrast: var(--ion-color-purple900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple900-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple900-shade);
  --ion-color-tint: var(--ion-color-purple900-tint);
}
.ion-color-purple800 {
  --ion-color-base: var(--ion-color-purple800);
  --ion-color-base-rgb: var(--ion-color-purple800-rgb);
  --ion-color-contrast: var(--ion-color-purple800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple800-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple800-shade);
  --ion-color-tint: var(--ion-color-purple800-tint);
}
.ion-color-purple700 {
  --ion-color-base: var(--ion-color-purple700);
  --ion-color-base-rgb: var(--ion-color-purple700-rgb);
  --ion-color-contrast: var(--ion-color-purple700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple700-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple700-shade);
  --ion-color-tint: var(--ion-color-purple700-tint);
}
.ion-color-purple600 {
  --ion-color-base: var(--ion-color-purple600);
  --ion-color-base-rgb: var(--ion-color-purple600-rgb);
  --ion-color-contrast: var(--ion-color-purple600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple600-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple600-shade);
  --ion-color-tint: var(--ion-color-purple600-tint);
}
.ion-color-purple500 {
  --ion-color-base: var(--ion-color-purple500);
  --ion-color-base-rgb: var(--ion-color-purple500-rgb);
  --ion-color-contrast: var(--ion-color-purple500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple500-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple500-shade);
  --ion-color-tint: var(--ion-color-purple500-tint);
}
.ion-color-purple400 {
  --ion-color-base: var(--ion-color-purple400);
  --ion-color-base-rgb: var(--ion-color-purple400-rgb);
  --ion-color-contrast: var(--ion-color-purple400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple400-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple400-shade);
  --ion-color-tint: var(--ion-color-purple400-tint);
}
.ion-color-purple300 {
  --ion-color-base: var(--ion-color-purple300);
  --ion-color-base-rgb: var(--ion-color-purple300-rgb);
  --ion-color-contrast: var(--ion-color-purple300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple300-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple300-shade);
  --ion-color-tint: var(--ion-color-purple300-tint);
}
.ion-color-purple200 {
  --ion-color-base: var(--ion-color-purple200);
  --ion-color-base-rgb: var(--ion-color-purple200-rgb);
  --ion-color-contrast: var(--ion-color-purple200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple200-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple200-shade);
  --ion-color-tint: var(--ion-color-purple200-tint);
}
.ion-color-purple100 {
  --ion-color-base: var(--ion-color-purple100);
  --ion-color-base-rgb: var(--ion-color-purple100-rgb);
  --ion-color-contrast: var(--ion-color-purple100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple100-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple100-shade);
  --ion-color-tint: var(--ion-color-purple100-tint);
}

.ion-color-violet950 {
  --ion-color-base: var(--ion-color-violet950);
  --ion-color-base-rgb: var(--ion-color-violet950-rgb);
  --ion-color-contrast: var(--ion-color-violet950-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet950-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet950-shade);
  --ion-color-tint: var(--ion-color-violet950-tint);
}
.ion-color-violet900 {
  --ion-color-base: var(--ion-color-violet900);
  --ion-color-base-rgb: var(--ion-color-violet900-rgb);
  --ion-color-contrast: var(--ion-color-violet900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet900-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet900-shade);
  --ion-color-tint: var(--ion-color-violet900-tint);
}
.ion-color-violet800 {
  --ion-color-base: var(--ion-color-violet800);
  --ion-color-base-rgb: var(--ion-color-violet800-rgb);
  --ion-color-contrast: var(--ion-color-violet800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet800-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet800-shade);
  --ion-color-tint: var(--ion-color-violet800-tint);
}
.ion-color-violet700 {
  --ion-color-base: var(--ion-color-violet700);
  --ion-color-base-rgb: var(--ion-color-violet700-rgb);
  --ion-color-contrast: var(--ion-color-violet700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet700-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet700-shade);
  --ion-color-tint: var(--ion-color-violet700-tint);
}
.ion-color-violet600 {
  --ion-color-base: var(--ion-color-violet600);
  --ion-color-base-rgb: var(--ion-color-violet600-rgb);
  --ion-color-contrast: var(--ion-color-violet600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet600-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet600-shade);
  --ion-color-tint: var(--ion-color-violet600-tint);
}
.ion-color-violet500 {
  --ion-color-base: var(--ion-color-violet500);
  --ion-color-base-rgb: var(--ion-color-violet500-rgb);
  --ion-color-contrast: var(--ion-color-violet500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet500-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet500-shade);
  --ion-color-tint: var(--ion-color-violet500-tint);
}
.ion-color-violet400 {
  --ion-color-base: var(--ion-color-violet400);
  --ion-color-base-rgb: var(--ion-color-violet400-rgb);
  --ion-color-contrast: var(--ion-color-violet400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet400-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet400-shade);
  --ion-color-tint: var(--ion-color-violet400-tint);
}
.ion-color-violet300 {
  --ion-color-base: var(--ion-color-violet300);
  --ion-color-base-rgb: var(--ion-color-violet300-rgb);
  --ion-color-contrast: var(--ion-color-violet300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet300-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet300-shade);
  --ion-color-tint: var(--ion-color-violet300-tint);
}
.ion-color-violet200 {
  --ion-color-base: var(--ion-color-violet200);
  --ion-color-base-rgb: var(--ion-color-violet200-rgb);
  --ion-color-contrast: var(--ion-color-violet200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet200-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet200-shade);
  --ion-color-tint: var(--ion-color-violet200-tint);
}
.ion-color-violet100 {
  --ion-color-base: var(--ion-color-violet100);
  --ion-color-base-rgb: var(--ion-color-violet100-rgb);
  --ion-color-contrast: var(--ion-color-violet100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-violet100-contrast-rgb);
  --ion-color-shade: var(--ion-color-violet100-shade);
  --ion-color-tint: var(--ion-color-violet100-tint);
}

.ion-color-light-blue950 {
  --ion-color-base: var(--ion-color-light-blue950);
  --ion-color-base-rgb: var(--ion-color-light-blue950-rgb);
  --ion-color-contrast: var(--ion-color-light-blue950-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue950-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue950-shade);
  --ion-color-tint: var(--ion-color-light-blue950-tint);
}
.ion-color-light-blue900 {
  --ion-color-base: var(--ion-color-light-blue900);
  --ion-color-base-rgb: var(--ion-color-light-blue900-rgb);
  --ion-color-contrast: var(--ion-color-light-blue900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue900-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue900-shade);
  --ion-color-tint: var(--ion-color-light-blue900-tint);
}
.ion-color-light-blue800 {
  --ion-color-base: var(--ion-color-light-blue800);
  --ion-color-base-rgb: var(--ion-color-light-blue800-rgb);
  --ion-color-contrast: var(--ion-color-light-blue800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue800-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue800-shade);
  --ion-color-tint: var(--ion-color-light-blue800-tint);
}
.ion-color-light-blue700 {
  --ion-color-base: var(--ion-color-light-blue700);
  --ion-color-base-rgb: var(--ion-color-light-blue700-rgb);
  --ion-color-contrast: var(--ion-color-light-blue700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue700-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue700-shade);
  --ion-color-tint: var(--ion-color-light-blue700-tint);
}
.ion-color-light-blue600 {
  --ion-color-base: var(--ion-color-light-blue600);
  --ion-color-base-rgb: var(--ion-color-light-blue600-rgb);
  --ion-color-contrast: var(--ion-color-light-blue600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue600-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue600-shade);
  --ion-color-tint: var(--ion-color-light-blue600-tint);
}
.ion-color-light-blue500 {
  --ion-color-base: var(--ion-color-light-blue500);
  --ion-color-base-rgb: var(--ion-color-light-blue500-rgb);
  --ion-color-contrast: var(--ion-color-light-blue500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue500-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue500-shade);
  --ion-color-tint: var(--ion-color-light-blue500-tint);
}
.ion-color-light-blue400 {
  --ion-color-base: var(--ion-color-light-blue400);
  --ion-color-base-rgb: var(--ion-color-light-blue400-rgb);
  --ion-color-contrast: var(--ion-color-light-blue400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue400-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue400-shade);
  --ion-color-tint: var(--ion-color-light-blue400-tint);
}
.ion-color-light-blue300 {
  --ion-color-base: var(--ion-color-light-blue300);
  --ion-color-base-rgb: var(--ion-color-light-blue300-rgb);
  --ion-color-contrast: var(--ion-color-light-blue300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue300-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue300-shade);
  --ion-color-tint: var(--ion-color-light-blue300-tint);
}
.ion-color-light-blue200 {
  --ion-color-base: var(--ion-color-light-blue200);
  --ion-color-base-rgb: var(--ion-color-light-blue200-rgb);
  --ion-color-contrast: var(--ion-color-light-blue200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue200-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue200-shade);
  --ion-color-tint: var(--ion-color-light-blue200-tint);
}
.ion-color-light-blue100 {
  --ion-color-base: var(--ion-color-light-blue100);
  --ion-color-base-rgb: var(--ion-color-light-blue100-rgb);
  --ion-color-contrast: var(--ion-color-light-blue100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-blue100-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-blue100-shade);
  --ion-color-tint: var(--ion-color-light-blue100-tint);
}

.ion-color-emerald950 {
  --ion-color-base: var(--ion-color-emerald950);
  --ion-color-base-rgb: var(--ion-color-emerald950-rgb);
  --ion-color-contrast: var(--ion-color-emerald950-contrast);
  --ion-color-contrast-rgb: var(--ion-color-emerald950-contrast-rgb);
  --ion-color-shade: var(--ion-color-emerald950-shade);
  --ion-color-tint: var(--ion-color-emerald950-tint);
}
.ion-color-emerald900 {
  --ion-color-base: var(--ion-color-emerald900);
  --ion-color-base-rgb: var(--ion-color-emerald900-rgb);
  --ion-color-contrast: var(--ion-color-emerald900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-emerald900-contrast-rgb);
  --ion-color-shade: var(--ion-color-emerald900-shade);
  --ion-color-tint: var(--ion-color-emerald900-tint);
}
.ion-color-emerald800 {
  --ion-color-base: var(--ion-color-emerald800);
  --ion-color-base-rgb: var(--ion-color-emerald800-rgb);
  --ion-color-contrast: var(--ion-color-emerald800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-emerald800-contrast-rgb);
  --ion-color-shade: var(--ion-color-emerald800-shade);
  --ion-color-tint: var(--ion-color-emerald800-tint);
}
.ion-color-emerald700 {
  --ion-color-base: var(--ion-color-emerald700);
  --ion-color-base-rgb: var(--ion-color-emerald700-rgb);
  --ion-color-contrast: var(--ion-color-emerald700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-emerald700-contrast-rgb);
  --ion-color-shade: var(--ion-color-emerald700-shade);
  --ion-color-tint: var(--ion-color-emerald700-tint);
}
.ion-color-emerald600 {
  --ion-color-base: var(--ion-color-emerald600);
  --ion-color-base-rgb: var(--ion-color-emerald600-rgb);
  --ion-color-contrast: var(--ion-color-emerald600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-emerald600-contrast-rgb);
  --ion-color-shade: var(--ion-color-emerald600-shade);
  --ion-color-tint: var(--ion-color-emerald600-tint);
}
.ion-color-emerald500 {
  --ion-color-base: var(--ion-color-emerald500);
  --ion-color-base-rgb: var(--ion-color-emerald500-rgb);
  --ion-color-contrast: var(--ion-color-emerald500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-emerald500-contrast-rgb);
  --ion-color-shade: var(--ion-color-emerald500-shade);
  --ion-color-tint: var(--ion-color-emerald500-tint);
}
.ion-color-emerald400 {
  --ion-color-base: var(--ion-color-emerald400);
  --ion-color-base-rgb: var(--ion-color-emerald400-rgb);
  --ion-color-contrast: var(--ion-color-emerald400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-emerald400-contrast-rgb);
  --ion-color-shade: var(--ion-color-emerald400-shade);
  --ion-color-tint: var(--ion-color-emerald400-tint);
}
.ion-color-emerald300 {
  --ion-color-base: var(--ion-color-emerald300);
  --ion-color-base-rgb: var(--ion-color-emerald300-rgb);
  --ion-color-contrast: var(--ion-color-emerald300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-emerald300-contrast-rgb);
  --ion-color-shade: var(--ion-color-emerald300-shade);
  --ion-color-tint: var(--ion-color-emerald300-tint);
}
.ion-color-emerald200 {
  --ion-color-base: var(--ion-color-emerald200);
  --ion-color-base-rgb: var(--ion-color-emerald200-rgb);
  --ion-color-contrast: var(--ion-color-emerald200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-emerald200-contrast-rgb);
  --ion-color-shade: var(--ion-color-emerald200-shade);
  --ion-color-tint: var(--ion-color-emerald200-tint);
}
.ion-color-emerald100 {
  --ion-color-base: var(--ion-color-emerald100);
  --ion-color-base-rgb: var(--ion-color-emerald100-rgb);
  --ion-color-contrast: var(--ion-color-emerald100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-emerald100-contrast-rgb);
  --ion-color-shade: var(--ion-color-emerald100-shade);
  --ion-color-tint: var(--ion-color-emerald100-tint);
}

.ion-color-yellow950 {
  --ion-color-base: var(--ion-color-yellow950);
  --ion-color-base-rgb: var(--ion-color-yellow950-rgb);
  --ion-color-contrast: var(--ion-color-yellow950-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow950-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow950-shade);
  --ion-color-tint: var(--ion-color-yellow950-tint);
}
.ion-color-yellow900 {
  --ion-color-base: var(--ion-color-yellow900);
  --ion-color-base-rgb: var(--ion-color-yellow900-rgb);
  --ion-color-contrast: var(--ion-color-yellow900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow900-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow900-shade);
  --ion-color-tint: var(--ion-color-yellow900-tint);
}
.ion-color-yellow800 {
  --ion-color-base: var(--ion-color-yellow800);
  --ion-color-base-rgb: var(--ion-color-yellow800-rgb);
  --ion-color-contrast: var(--ion-color-yellow800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow800-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow800-shade);
  --ion-color-tint: var(--ion-color-yellow800-tint);
}
.ion-color-yellow700 {
  --ion-color-base: var(--ion-color-yellow700);
  --ion-color-base-rgb: var(--ion-color-yellow700-rgb);
  --ion-color-contrast: var(--ion-color-yellow700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow700-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow700-shade);
  --ion-color-tint: var(--ion-color-yellow700-tint);
}
.ion-color-yellow600 {
  --ion-color-base: var(--ion-color-yellow600);
  --ion-color-base-rgb: var(--ion-color-yellow600-rgb);
  --ion-color-contrast: var(--ion-color-yellow600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow600-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow600-shade);
  --ion-color-tint: var(--ion-color-yellow600-tint);
}
.ion-color-yellow500 {
  --ion-color-base: var(--ion-color-yellow500);
  --ion-color-base-rgb: var(--ion-color-yellow500-rgb);
  --ion-color-contrast: var(--ion-color-yellow500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow500-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow500-shade);
  --ion-color-tint: var(--ion-color-yellow500-tint);
}
.ion-color-yellow400 {
  --ion-color-base: var(--ion-color-yellow400);
  --ion-color-base-rgb: var(--ion-color-yellow400-rgb);
  --ion-color-contrast: var(--ion-color-yellow400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow400-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow400-shade);
  --ion-color-tint: var(--ion-color-yellow400-tint);
}
.ion-color-yellow300 {
  --ion-color-base: var(--ion-color-yellow300);
  --ion-color-base-rgb: var(--ion-color-yellow300-rgb);
  --ion-color-contrast: var(--ion-color-yellow300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow300-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow300-shade);
  --ion-color-tint: var(--ion-color-yellow300-tint);
}
.ion-color-yellow200 {
  --ion-color-base: var(--ion-color-yellow200);
  --ion-color-base-rgb: var(--ion-color-yellow200-rgb);
  --ion-color-contrast: var(--ion-color-yellow200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow200-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow200-shade);
  --ion-color-tint: var(--ion-color-yellow200-tint);
}
.ion-color-yellow100 {
  --ion-color-base: var(--ion-color-yellow100);
  --ion-color-base-rgb: var(--ion-color-yellow100-rgb);
  --ion-color-contrast: var(--ion-color-yellow100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow100-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow100-shade);
  --ion-color-tint: var(--ion-color-yellow100-tint);
}

.ion-color-amber950 {
  --ion-color-base: var(--ion-color-amber950);
  --ion-color-base-rgb: var(--ion-color-amber950-rgb);
  --ion-color-contrast: var(--ion-color-amber950-contrast);
  --ion-color-contrast-rgb: var(--ion-color-amber950-contrast-rgb);
  --ion-color-shade: var(--ion-color-amber950-shade);
  --ion-color-tint: var(--ion-color-amber950-tint);
}
.ion-color-amber900 {
  --ion-color-base: var(--ion-color-amber900);
  --ion-color-base-rgb: var(--ion-color-amber900-rgb);
  --ion-color-contrast: var(--ion-color-amber900-contrast);
  --ion-color-contrast-rgb: var(--ion-color-amber900-contrast-rgb);
  --ion-color-shade: var(--ion-color-amber900-shade);
  --ion-color-tint: var(--ion-color-amber900-tint);
}
.ion-color-amber800 {
  --ion-color-base: var(--ion-color-amber800);
  --ion-color-base-rgb: var(--ion-color-amber800-rgb);
  --ion-color-contrast: var(--ion-color-amber800-contrast);
  --ion-color-contrast-rgb: var(--ion-color-amber800-contrast-rgb);
  --ion-color-shade: var(--ion-color-amber800-shade);
  --ion-color-tint: var(--ion-color-amber800-tint);
}
.ion-color-amber700 {
  --ion-color-base: var(--ion-color-amber700);
  --ion-color-base-rgb: var(--ion-color-amber700-rgb);
  --ion-color-contrast: var(--ion-color-amber700-contrast);
  --ion-color-contrast-rgb: var(--ion-color-amber700-contrast-rgb);
  --ion-color-shade: var(--ion-color-amber700-shade);
  --ion-color-tint: var(--ion-color-amber700-tint);
}
.ion-color-amber600 {
  --ion-color-base: var(--ion-color-amber600);
  --ion-color-base-rgb: var(--ion-color-amber600-rgb);
  --ion-color-contrast: var(--ion-color-amber600-contrast);
  --ion-color-contrast-rgb: var(--ion-color-amber600-contrast-rgb);
  --ion-color-shade: var(--ion-color-amber600-shade);
  --ion-color-tint: var(--ion-color-amber600-tint);
}
.ion-color-amber500 {
  --ion-color-base: var(--ion-color-amber500);
  --ion-color-base-rgb: var(--ion-color-amber500-rgb);
  --ion-color-contrast: var(--ion-color-amber500-contrast);
  --ion-color-contrast-rgb: var(--ion-color-amber500-contrast-rgb);
  --ion-color-shade: var(--ion-color-amber500-shade);
  --ion-color-tint: var(--ion-color-amber500-tint);
}
.ion-color-amber400 {
  --ion-color-base: var(--ion-color-amber400);
  --ion-color-base-rgb: var(--ion-color-amber400-rgb);
  --ion-color-contrast: var(--ion-color-amber400-contrast);
  --ion-color-contrast-rgb: var(--ion-color-amber400-contrast-rgb);
  --ion-color-shade: var(--ion-color-amber400-shade);
  --ion-color-tint: var(--ion-color-amber400-tint);
}
.ion-color-amber300 {
  --ion-color-base: var(--ion-color-amber300);
  --ion-color-base-rgb: var(--ion-color-amber300-rgb);
  --ion-color-contrast: var(--ion-color-amber300-contrast);
  --ion-color-contrast-rgb: var(--ion-color-amber300-contrast-rgb);
  --ion-color-shade: var(--ion-color-amber300-shade);
  --ion-color-tint: var(--ion-color-amber300-tint);
}
.ion-color-amber200 {
  --ion-color-base: var(--ion-color-amber200);
  --ion-color-base-rgb: var(--ion-color-amber200-rgb);
  --ion-color-contrast: var(--ion-color-amber200-contrast);
  --ion-color-contrast-rgb: var(--ion-color-amber200-contrast-rgb);
  --ion-color-shade: var(--ion-color-amber200-shade);
  --ion-color-tint: var(--ion-color-amber200-tint);
}
.ion-color-amber100 {
  --ion-color-base: var(--ion-color-amber100);
  --ion-color-base-rgb: var(--ion-color-amber100-rgb);
  --ion-color-contrast: var(--ion-color-amber100-contrast);
  --ion-color-contrast-rgb: var(--ion-color-amber100-contrast-rgb);
  --ion-color-shade: var(--ion-color-amber100-shade);
  --ion-color-tint: var(--ion-color-amber100-tint);
}
