.measurementCard {
  background-color: white;
  /* width: 45%; */
  height: 290px;
}

.loading_Content {
  margin-top: 10px;
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaded_Content {
  padding-top: 20px !important;

  height: 210px;
}
