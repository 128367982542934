.appBackground {
  --background: #f0f0f7;
}

@media screen and (prefers-color-scheme: dark) {
  .appBackground {
    --background: #000000;
  }
}

.cardHeader {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.cardContent {
  padding: 10px !important;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

ion-img {
  display: block;
  max-width: 100%;
}

.horizontal-center {
  margin: auto;
  display: block;
}

ion-searchbar.md {
  --border-radius: 10px;
}

.uploadIcon {
  height: 100px;
  width: 100px;
  /* position: fixed;
  bottom: 10px; */
  align-self: center;
  color: var(--ion-color-primary-grey200);
}

.scrollable {
  --overflow-y: scroll !important;
  overflow-x: hidden;
  height: calc(100vh - 200px);
  scroll-behavior: auto;
  padding-bottom: 20px;
  margin-top: 10px;
}

.full_width {
  width: 100%;
}

.full_height {
  height: 100%;
}

.third_height {
  height: 30% !important;
}

.two_third_height {
  height: 66% !important;
}

ion-item::part(native).item-inner {
  padding-inline-end: 0 !important;
  --padding-inline-end: 0 !important;
  padding-left: 0 !important;
  --padding-left: 0 !important;
  padding-right: 0 !important;
  --padding-right: 0 !important;
  padding-inline-start: 0 !important;
  --padding-inline-start: 0 !important;
}

/* ============================================ Radio Buttons / Checkboxes ============================================ */
ion-radio {
  width: 30px;
  height: 30px;
}

ion-radio::part(container) {
  border-radius: 8px;
  border: 2px solid #ddd;
}

ion-radio::part(mark) {
  background: none;
  transition: none;
  transform: none;
  border-radius: 0;
}

ion-radio.radio-checked::part(container) {
  background: var(--blueGradient);
  border-color: transparent;
  box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
}

ion-radio.radio-checked::part(mark) {
  width: 6px;
  height: 10px;

  border-width: 0px 2px 2px 0px;
  border-style: solid;
  border-color: #fff;

  transform: rotate(45deg);
}

.disabled {
  opacity: 0.2;
}
/* ============================================ Toggle ============================================ */

.icon-custom {
  --handle-background-checked: #fff url('../assets/icons/ORYX_PowerIcon.svg') no-repeat center / contain;
  --handle-background: #fff url('../assets/icons/ORYX_PowerIcon.svg') no-repeat center / contain;
  height: 20px;
  --handle-width: 16px;
  margin-top: 5px;
}

.icon-custom.toggle-checked::before {
  position: absolute;
  content: 'ON';
  color: white;
  top: 50%;
  left: 10px;
  z-index: 1;
  font-size: 8px;
  transform: translateY(-50%);
}

/* ============================================ IonCards ============================================ */

ion-card-header {
  height: 48px;
  color: var(--ion-color-primary);
  font-size: 24px;
  line-height: 48px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  padding-top: 0 !important;
}

.ionCardHeader_BorderBottom {
  border-bottom: 1px solid #ddd;
  box-shadow: none !important;
}

ion-card-title {
  font-size: 24px;
  font-weight: bold;
  line-height: 48px;
  color: var(--ion-color-primary);
}
ion-card-subtitle {
  font-size: 14px;
  line-height: 20px;
  text-transform: none;
  color: var(--ion-text-color);
}

.cardHeaderButton_Right {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  font-size: 18px;
  /* position: fixed;
  bottom: 10px; */
  align-self: center;
  color: #4d4f5c;
  --ionicon-stroke-width: 16px;
}

.dashboardCard_Full {
  min-height: 550px;
  max-height: 1500px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.content-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  height: 90% !important;
  max-height: 90% !important;
  padding-top: 5px !important;
}

.Modalcontent-container {
  display: grid;
  grid-template-rows: 75px 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  height: 90% !important;
  max-height: 90% !important;
  /* border: 1px solid blue; */
}

.Modal_Spinner {
  margin: auto auto;
  display: block;
}
/* ============================================ Alerts ============================================ */

.destructiveAlert .alert-wrapper .alert-head h2 {
  color: var(--ion-color-error600) !important;
  /* background-color: var(--ion-color-error600); */
}

.informativeAlert .alert-wrapper .alert-head h2 {
  color: var(--ion-color-primary) !important;

  /* background-color: var(--ion-color-error600); */
}

.destructiveAlertButton {
  color: var(--ion-color-error600) !important;
}

/* ============================================ Popovers ============================================ */
ion-popover {
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --color: white;
  --width: fit-content;
}

.border-right {
  border-right: 1px solid #ddd;
}
/* ============================================ Modals ============================================ */
.ORYX_Modal_Large {
  --width: 95%;
  --height: 95%;
  --border-radius: 10px;
}

.modal_Large_Vertical {
  --height: 90%;
  --border-radius: 10px;
}

.ORYX_Modal_Medium {
  --width: 50%;
  --height: 50%;
  --border-radius: 10px;
}

ion-modal {
  --ion-background-color: #ffffff;
}

.ORYX_ModalButton_Close {
  height: 80px;
  font-size: 58px;
  /* position: fixed;
  bottom: 10px; */
  align-self: center;
  color: #4d4f5c40;
  --ionicon-stroke-width: 16px;
}

/* ============================================ Tab Bars ============================================ */
ion-tab-button {
  --color: var(--ion-color-primary-grey200);
  --color-selected: var(--ion-color-primary-grey200);
  --background-selected: var(--ion-color-primary);

  --padding-start: 0px;
  padding-bottom: 10px;
  padding-top: 5px;
  border-radius: 16px !important;
}

ion-tab-button:hover {
  background-color: #70707030;
}

.ion-tab-button_selected {
  background-color: var(--ion-color-primary);
  color: #ffffff;
}

ion-tab-bar {
  display: flex;
  justify-content: center;
  --background: #ffffff;
}

ion-tab-bar.floating {
  --background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.4);
  border-radius: 16px !important;

  height: 50px;
  width: 90%;
  padding-top: 5px;
  padding-bottom: 5px;
  top: 10px;
  position: relative;
  margin: 0 auto !important;
  border-top: none;
}
/* ============================================ Grid & Rows & Columns ============================================ */
.border_Bottom {
  border-bottom: 1px solid #ddd;
}

.border_Top {
  border-top: 1px solid #ddd;
}

.border_Right {
  border-right: 1px solid #ddd;
}

.fullWidth {
  width: 100% !important;
}

.fullHeight {
  height: 100% !important;
}

.minHeight600 {
  height: 619px !important;
  max-height: 619px;
}

.margin_Right {
  margin-right: 2px;
  padding-right: 2px;
}

/* ============================================ UploadArea ============================================ */
.uploadArea_ThumbnailPreview_Large {
  height: 100%;
  border-radius: 14px !important;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

.uploadArea_VideoPreview_Large {
  height: 100%;
  border-radius: 14px !important;
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
}

/* ============================================ Thumbnails ============================================ */
.ion-thumbnail-small {
  --size: 40px;
  --border-radius: 8px;
}

.ion-thumbnail-medium {
  --size: 60px;
  --border-radius: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

ion-thumbnail {
  --size: 140px;
  --border-radius: 14px;
}
/* ============================================ Text ============================================ */
.TextAlign_Center {
  text-align: center !important;
  margin-right: 0 !important;
}

.ghost {
  opacity: 0.5;
}

.clone {
  opacity: 0.2;
}

.small_Text {
  font-size: 8px;
}

.graph_Title {
  font-size: 10px;
  font-weight: 900;
  color: var(--ion-color-primary);
}

.ORYX_GraphTitle {
  text-align: center !important;
  font-size: 18px;
  color: var(--ion-color-primary);
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin: 0;
}

.ORYX_GraphTitle_LeftAligned {
  text-align: left;
  font-size: 18px;
  color: var(--ion-color-primary);
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin: 0;
  padding-left: 10px;
}

.ORYX_GraphTitle_Vertical {
  text-align: center !important;
  font-size: 18px;
  color: var(--ion-color-primary);
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin: auto;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

.variabilityCard_Header_Buttons {
  height: 20px;
  font-size: 14px !important;
  align-self: center;
  color: #4d4f5c40;
}

.errorText {
  color: var(--ion-color-error600);
}

/* ============================================ Ion Range ============================================ */
ion-range {
  --bar-height: 4px;
  --bar-border-radius: 8px;
}

ion-range::part(knob) {
  background: var(--blueGradient);
}

ion-range::part(tick) {
  height: 6px;
  background-color: var(--tertiary900);
}
ion-range::part(tick-active) {
  background: var(--ion-color-primary);
}

/* ============================================ Recharts & Apexcharts ============================================ */
.recharts-wrapper {
  margin: 0 auto;
}

.variabilityGraph_Wrapper {
  width: 100%;
  /* max-height: 95px; */
  padding: 0;
  margin: 0;
}

.variabilityGraph_Wrapper_Small {
  width: 100%;
  max-height: 95px !important;
  padding: 0;
  margin: 0;
}

.sideLabel {
  margin-top: 2px !important;
  font-size: 8px !important;
  text-align: center;
  justify-self: center;
}

.variabilityJointGraph_Wrapper {
  height: 45%;
  max-height: 45%;
  width: 99%;
  border: 1px solid blue;
  padding: 0;
  margin: 0;
}

.variabilityJointGraphModal_Wrapper {
  height: 100%;
  max-height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.projectionsFootplantGraph_Wrapper {
  width: 100%;
  max-height: 115px;
}

.kinematicsGraph_Wrapper {
  width: 100%;
  max-height: 350px;
}

.legend_popover {
  --max-width: 130px;
}

.legend_entry {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.legend_dot {
  display: inline-block;
  margin: 5px;
  height: 8px !important;
  width: 8px !important;
  background-color: orange;
  border-radius: 50%;
}

.legend_name {
  display: inline-block;
  margin: 5px;
  right: 0;
  font-size: 0.6rem;
}
.legend_name_small {
  display: inline-block;
  margin: 5px;
  right: 0;
  font-size: 0.5rem;
}

.variabilityLegend_Row {
  height: 75px;
}

.variabilityLegend {
  --background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.4);
  border-radius: 16px !important;
  height: 50px;
  width: 90%;
  padding-top: 5px;
  padding-bottom: 5px;
  top: 10px;
  position: relative;
  margin: 0 auto !important;
  border-top: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.variabilityLegend_Entry {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 150px;
  height: 48px;
  border-radius: 16px;
  justify-content: center;
  /* border: 1px solid blue; */
}

.variabilityLegend_Entry:hover {
  cursor: pointer;
  background-color: #70707030;
}

.variabilityLegend_Entry_Selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 150px;
  height: 48px;
  border-radius: 16px;
  justify-content: center;
  position: relative;
  /* border: 1px solid blue; */
}

.variabilityLegend_Entry_Selected:hover {
  cursor: pointer;
  background-color: #70707030;
}

.variabilityLegend_Entry_Selected::before {
  display: block;
  content: '';
  width: 80%;
  height: 5px;
  border-radius: 16px;
  background: var(--ion-color-primary);
  position: absolute;
  bottom: 10px;
}

.variabilityModalGraphRow {
  /* --background: #ffffff;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.4);
  border-radius: 16px !important; */
  height: 50%;
}

.Vertical {
  display: flex;
  flex-direction: column;
}

/* ============================================ Table ============================================ */
.ORYX_Table_Indent {
  padding-left: 10px !important;
  font-style: italic;
  font-weight: normal !important;
}

.ORYX_Table {
  border-collapse: collapse;
  width: 100%;
  font-size: 10px;
  margin-top: 5px;
  /* margin-left: 5px; */
}
.ORYX_Table_noMargin {
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
}

.ORYX_Table td,
.ORYX_Table th {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center !important;
}

.ORYX_Table_noMargin td,
.ORYX_Table_noMargin th {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center !important;
}

.ORYX_Table td:first-child {
  font-weight: 900;
  text-align: left !important;
}
.ORYX_Table_noMargin td:first-child {
  font-weight: 900;
  text-align: left !important;
  padding-left: 5px;
}

.ORYX_Table_Head {
  background-color: #f2f2f2;
}

.ORYX_Table tr:hover {
  background-color: #ddd;
}

.ORYX_Table_noMargin tr:hover {
  background-color: #ddd;
}
.ORYX_Table th {
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
  background-color: white;
  color: var(--ion-color-primary);
}
.ORYX_Table_noMargin th {
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
  background-color: white;
  color: var(--ion-color-primary);
}
/* !! Table Large */
.ORYX_Table_Large {
  border-collapse: collapse;
  width: 98%;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
}

.ORYX_Table_Large_Indent {
  padding-left: 20px !important;
  font-style: italic;
  font-weight: normal !important;
}

.ORYX_Table_Large td,
.ORYX_Table_Large th {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center !important;
}

.ORYX_Table_Large td:first-child {
  font-weight: 900;
  text-align: left !important;
  padding-left: 10px;
}

.ORYX_Table_Large_NewFirstChild {
  font-weight: normal !important;
  text-align: center !important;
  padding-left: 10px;
}

.ORYX_Table_Large td:last-child {
  width: 100px;
  text-align: left !important;
}

.ORYX_Table_Large tr:hover {
  background-color: #ddd;
}

.ORYX_Table_Large th {
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
  background-color: white;
  color: var(--ion-color-primary);
}

/* !! ROM Table Large */
.ORYX_ROMTable_Large {
  border-collapse: collapse;
  width: 98%;
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
}

.ORYX_ROMTable_Large_Indent {
  padding-left: 20px !important;
  font-style: italic;
  font-weight: normal !important;
}

.ORYX_ROMTable_Large td,
.ORYX_ROMTable_Large th {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center !important;
}

.ORYX_ROMTable_Large td:first-child {
  font-weight: 900;
  text-align: left !important;
  padding-left: 10px;
}

.ORYX_ROMTable_Large tr:hover {
  background-color: #ddd;
}

.ORYX_ROMTable_Large th {
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
  background-color: white;
  color: var(--ion-color-primary);
}

.No_Border_Bottom {
  border-bottom: none !important;
}
.No_Border_Top {
  border-top: none !important;
}

.ORYX_List {
  border-collapse: collapse;
  width: 100%;
  font-size: 10px;
  margin-top: 5px;
  /* margin-left: 5px; */
}
.ORYX_List td:first-child {
  font-weight: 900;
  text-align: left !important;
  width: 45% !important;
}

.ORYX_List td,
.ORYX_List th {
  padding: 2px;
  border: 1px solid #ddd;
}

.Label {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary, #2066fa) !important;
  margin-top: 10px !important;
}

.backgroundGreen {
  background-color: var(--ion-color-success600) !important;
  color: var(--ion-color-primary-grey800);
}

.backgroundOrange {
  background-color: var(--ion-color-amber400) !important;
  color: var(--ion-color-primary-grey800);
}

.backgroundRed {
  background-color: var(--ion-color-error700) !important;
  color: #ffffff;
}

/* !! ============================================ Table ============================================ !! */
/* .tableWrapper {
  width: 100%;
  height: 100%;
  max-height: 99% !important;
  overflow: auto;
  border: 1px solid purple;
} */

.ORYX_Table_Scroll {
  border-collapse: collapse;
  width: 100%;
  font-size: 10px;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.ORYX_Table_Scroll thead th {
  position: sticky;
  top: 0;
}
.ORYX_Table_Scroll td,
.ORYX_Table_Scroll th {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center !important;
}

.ORYX_Table_Scroll td:first-child {
  font-weight: 900;
  text-align: left !important;
}

.ORYX_Table_Scroll tr:hover {
  background-color: #ddd;
}

.ORYX_Table_Scroll th {
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: left;
  background-color: white;
  color: var(--ion-color-primary);
}

/* ============================================ Lists & Items ============================================ */

ion-item.create_ListItem {
  --background: #ffffff;
  --padding-bottom: 10px;
  --padding-end: 10px;
  --padding-start: 10px;
  --padding-top: 10px;
  --highlight-height: 2px;
  --highlight-color-focused: var(--ion-color-primary);
  --highlight-color-valid: var(--ion-color-success600);
  --highlight-color-invalid: var(--ion-color-error600);
}
.tagsList {
  width: 100% !important;
  background-color: transparent;
}

.IonLabel_Small {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.disabled {
  opacity: 0.2;
}

.create_ListItem {
  margin: 12px 20px;
  border-radius: 4px;
  border: 1px solid #e4e7e8;
  margin-left: 5px;
  margin-right: 5px;
}

.create_ListItemError {
  margin: 12px 20px;
  border-radius: 4px;
  border: 1px solid red;
  margin-left: 5px;
  margin-right: 5px;
}
.create_ListItem_Div {
  margin: 12px 20px;
  border-radius: 4px;
  border: 1px solid #e4e7e8;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 10px;
}
.create_ListItemError_Div {
  margin: 12px 20px;
  border-radius: 4px;
  border: 1px solid red;
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 10px;
}

.create_Label {
  font-weight: bold;
  width: fit-content;
}

.button_Item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: blue;
}
