.spinner {
  float: right;
  top: -25px;
}

.error {
  float: right;
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
.reportcardHeader p {
  max-width: 85%;
  margin: 0px;
}

.card {
  height: 300px;
}

.reportCard:hover {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to bottom, var(--ion-color-primary), #1649d8) border-box;
  border-radius: 8px;
  border: 4px solid transparent;
  transition: 0.3s all ease-in-out;
}

.cardError {
  height: 300px;
  border: 2px solid var(--ion-color-danger);
}

.errorItem {
  margin: 16px 0px 0px;
}

.reportcardHeader {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  text-align: left;
}

.reportcardContent {
  padding: 10px !important;
}

.areasOfConcernGreen {
  background-color: var(--ion-color-success);
  border-radius: 10px;
}

.areasOfConcernOrange {
  background-color: var(--ion-color-warning);
  border-radius: 10px;
}
.actionButton {
  position: absolute;
  right: 0px;
  top: -10px;
}
action-sheet-icon {
  padding-right: 20px !important;
}
.download_Modal .modal-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  width: 200px;
  position: fixed;
  margin: auto;
  height: 200px;
  display: flex;
  justify-content: center;
}

.download_Modal_Download {
  width: 200px;
  height: 200px;
}
.download_Modal_DownloadIcon {
  left: 71px;
  top: 50px;
  position: relative;
  font-size: 58px;
  color: #2066fa;
  --ionicon-stroke-width: 24px;
}

.download_Modal_DownloadLink {
  top: 45px;
  position: relative;
  font-size: 12px;
  text-decoration: underline;
  text-align: center;
}

/* ========================== SPINNER ========================== */
.sk-fading-circle {
  margin: 75px auto;
  width: 50px;
  height: 50px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #2066fa;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.reportCard_Badge {
  position: absolute;
  right: 10px;
  bottom: 10px;
  margin-top: auto;
}
