.avatar {
  position: absolute;
  display: block;
  width: 131.346px;
  height: 393.41px;
  overflow: visible;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0 !important;
}
/* Head */
#head {
  position: absolute;
  width: 39.562px;
  height: 54.759px;
  left: 46.273px;
  top: 0px;
  overflow: visible;
  opacity: 0.2;
}
.head_segment {
  overflow: visible;
  position: absolute;
  width: 40.544px;
  height: 55.759px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
/* Trunk */
#trunk {
  position: absolute;
  width: 69.311px;
  height: 119.766px;
  left: 30.997px;
  top: 52.692px;
  overflow: visible;
  opacity: 0.2;
}
.trunk_segment {
  overflow: visible;
  position: absolute;
  width: 70.293px;
  height: 121.06px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
/* Right Arm */
#right_arm {
  transform: translate(-3.1px, -1.942px) matrix(1, 0, 0, 1, 0, 0);
  transform-origin: center;
  position: absolute;
  width: 31.03px;
  height: 69.48px;
  left: 5px;
  top: 74.269px;
  overflow: visible;
  opacity: 0.2;
}
.right_arm_segment {
  overflow: visible;
  position: absolute;
  width: 31.03px;
  height: 69.48px;
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
/* Right Forearm */
#right_forearm {
  overflow: visible;
  position: absolute;
  width: 32.03px;
  height: 87.495px;
  left: -5px;
  top: 142px;
  opacity: 0.2;
}
.right_forearm_segment {
  overflow: visible;
  position: absolute;
  width: 32.03px;
  height: 87.495px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
/* Left Arm */
#left_arm {
  transform: translate(-3.1px, -1.942px) matrix(1, 0, 0, 1, 0, 0);
  transform-origin: center;
  position: absolute;
  width: 31.03px;
  height: 69.48px;
  left: 101.316px;
  top: 74.269px;
  overflow: visible;
  opacity: 0.2;
}
.left_arm_segment {
  overflow: visible;
  position: absolute;
  width: 31.03px;
  height: 69.48px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
/* Left Forearm */
#left_forearm {
  transform: translate(-3.1px, -1.942px) matrix(1, 0, 0, 1, 0, 0);
  transform-origin: center;
  position: absolute;
  width: 32.03px;
  height: 87.495px;
  left: 106.316px;
  top: 144px;
  overflow: visible;
  opacity: 0.2;
}
.left_forearm_segment {
  overflow: visible;
  position: absolute;
  width: 32.03px;
  height: 87.495px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
/* Left Pelvis */
#left_pelvis {
  position: absolute;
  width: 36.371px;
  height: 38.835px;
  left: 66.651px;
  top: 169.714px;
  overflow: visible;
}
.left_pelvis_segment {
  overflow: visible;
  position: absolute;
  width: 36.371px;
  height: 38.835px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
/* Right Pelvis */
#right_pelvis {
  position: absolute;
  width: 36.371px;
  height: 38.835px;
  left: 29.28px;
  top: 169.714px;
  overflow: visible;
}
.right_pelvis_segment {
  overflow: visible;
  position: absolute;
  width: 36.371px;
  height: 38.835px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

/* Left Thigh */
#left_thigh {
  position: absolute;
  width: 35.763px;
  height: 85.346px;
  left: 65.54px;
  top: 205.187px;
  overflow: visible;
}
.left_thigh_segment {
  overflow: visible;
  position: absolute;
  width: 36.754px;
  height: 86.336px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
/* Left Calf */
#left_calf {
  position: absolute;
  width: 25.796px;
  height: 86.802px;
  left: 69.344px;
  top: 289.292px;
  overflow: visible;
}
.left_calf_segment {
  overflow: visible;
  position: absolute;
  width: 26.737px;
  height: 87.941px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
/* Left Foot */
#left_foot {
  position: absolute;
  width: 23.783px;
  height: 16.468px;
  left: 68.178px;
  top: 376.942px;
  overflow: visible;
}
.left_foot_segment {
  overflow: visible;
  position: absolute;
  width: 24.869px;
  height: 17.37px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

/* Right Thigh */
#right_thigh {
  position: absolute;
  width: 35.7px;
  height: 84.551px;
  left: 28.843px;
  top: 205.671px;
  overflow: visible;
}
.right_thigh_segment {
  overflow: visible;
  position: absolute;
  width: 36.698px;
  height: 85.541px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
/* Right Calf */
#right_calf {
  position: absolute;
  width: 25.817px;
  height: 86.802px;
  left: 33.106px;
  top: 289.292px;
  overflow: visible;
}
.right_calf_segment {
  overflow: visible;
  position: absolute;
  width: 26.754px;
  height: 87.941px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
/* Right Foot */
#right_foot {
  transform: translate(-3.1px, -1.942px) matrix(1, 0, 0, 1, 0, 0) rotate(180deg);
  transform-origin: center;
  position: absolute;
  width: 23.782px;
  height: 16.468px;
  left: 40.181px;
  top: 379.942px;
  overflow: visible;
}
.right_foot_segment {
  overflow: visible;
  position: absolute;
  width: 24.867px;
  height: 17.371px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  fill: transparent;
  stroke: rgba(0, 0, 0, 1);
  stroke-width: 0.7611546516418457px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}

.fill {
  fill: rgb(10, 71, 248) !important;
}