/* ORYX Training Builder Tool 1.0
 *
 * Created by ORYX Movement Solutions © 2022
 * ==================================================================
 *
 * ORYX Custom typography. For more information see:
 * https://xd.adobe.com/view/df617fb9-9076-4eeb-a2d2-54a5a3a05da7-8a32/.
 */

/** font-face imports: **/
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Montserrat-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/Montserrat-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('../assets/fonts/Montserrat-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: url('../assets/fonts/Montserrat-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: url('../assets/fonts/Montserrat-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: url('../assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: url('../assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: url('../assets/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: url('../assets/fonts/Montserrat-BlackItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: url('../assets/fonts/Montserrat-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('../assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: url('../assets/fonts/Montserrat-ThinItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: url('../assets/fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Montserrat-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: url('../assets/fonts/Montserrat-LightItalic.ttf') format('truetype');
}

/** end font-face imports **/

html {
  font-family: 'Montserrat', sans-serif;
}

a {
  background-color: transparent;
  color: var(--ion-color-primary, #2066fa);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  margin-top: 16px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 1.2;
}
h1 {
  margin-top: 20px;
  font-size: 52px;
  font-weight: 700;
  color: var(--primary, #2066fa);
}
h2 {
  margin-top: 18px;
  font-size: 42px;
  font-weight: 700;
  color: var(--primary, #2066fa);
}
h3 {
  font-size: 38px;
  font-weight: 700;
  color: var(--primary, #2066fa);
}

h4 {
  font-size: 24px;
  font-weight: 700;
  color: var(--primary, #2066fa);
}

h5 {
  font-size: 20px;
  font-weight: 700;
  color: var(--primary, #2066fa);
}

h6 {
  font-size: 18px;
  font-weight: 700;
  color: var(--ion-text-color, #707070);
}

small {
  font-family: 'Montserrat', sans-serif;
  font-size: 75%;
}

smallError {
  font-family: 'Montserrat', sans-serif;
  font-size: 75%;
  color: red;
}

p {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: var(--ion-text-color, #707070);
}
.subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: var(--ion-text-color, #707070);
}

.label {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: var(--primary, #2066fa) !important;
  margin-top: 10px !important;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}
