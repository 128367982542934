.attractorCard {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.attractorCard_Error {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0 !important;
  border: 1px solid var(--ion-color-danger);
  margin-top: 0 !important;
}

.attractorCard_Header {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  height: 60px;
  text-align: left;
  padding: 0 !important;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis;
}

.attractorCard_Title {
  font-size: 14px;
  height: 25px !important;
  margin-right: 0px !important;
  line-height: 1.5rem;
  padding-left: 10px;
}

.attractorCard_Subtitle {
  font-size: 10px;
  padding-left: 10px;
  height: 25px !important;
  margin-right: 0px !important;
}

.attractorCard_Header_Buttons {
  height: 35px;
  font-size: 18px;
  align-self: center;
  color: #4d4f5c40;
}
.attractorCard_Header_Row {
  height: 70px !important;
}

.attractorCard_Avatar_Run {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 256px;
}
.ORYX_DashboardCard {
  margin: 0 !important;
  padding: 12px 10px;
  height: 100% !important;
  max-height: 100%;
  width: 100%;
}
