.sensorImage {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.scannedSensorImage {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  opacity: 0.4;
}
.content_center_col {
  display: flex;
}

.sensorIcon {
  height: 80px;
  font-size: 58px;
  display: inline-block;
  align-self: center;
  color: var(--ion-color-danger);
  --ionicon-stroke-width: 20px;
}

.noData {
  height: 80px;
  font-size: 58px;
  color: var(--ion-color-danger);
  --ionicon-stroke-width: 60px;
  margin-top: -60px;
  float: right;
  margin-right: 30px;
}

.data {
  height: 80px;
  font-size: 58px;
  color: var(--ion-color-success);
  --ionicon-stroke-width: 60px;
  margin-top: -60px;
  float: right;
  margin-right: 30px;
}
.chargingBatteryIcon {
  height: 57.7px;
  width: 100px;
  color: black;
}

.animate-sensor-pop-in {
  animation-duration: 0.4s;
  animation-name: animate-popin;
  animation-fill-mode: backwards;
}

@keyframes animate-sensor-pop-in {
  0% {
    transform: scale(0.0, 0.0);
  }


  100% {
    transform: scale(1, 1);
  }
}
