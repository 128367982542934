ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
  --max-height: 98vh;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
  padding-top: var(--ion-safe-area-top, 0);
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #4d4f5c;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 34px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #4d4f5c;
  font-size: 26px;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 34px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 26px;
  color: #4d4f5c;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
  border-left: 5px solid #2066fa;
  --ion-item-background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-item.disabled {
  --ion-item-background: lightgrey;
  opacity: 0.2;
}
ion-content.md.menu::part(scroll) {
  padding-top: 0px;
}
ion-content.md.menu-content-push::part(scroll) {
  padding-top: 0px;
}

ion-content.ios.menu::part(scroll) {
  padding-top: 20px;
}
ion-content.ios.menu-content-push::part(scroll) {
  padding-top: 20px;
}
.small {
  max-width: 100px !important;
  min-width: fit-content;
  border-right: 1px solid #4d4f5c40;
}

.oryx_header {
  height: 56px;
  align-content: center;
  padding-top: 8px;
}

.header__logo {
  width: 120px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.header_logo_small {
  width: 35px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.menuToggleButton {
  padding: 0px !important;
  width: 100%;
  font-size: 26px;
  text-align: center;
  align-self: center;
  color: #4d4f5c40 !important;
}
.oryx_searchbar {
  padding: 0px 0px 0px 45px;
  top: -20px;
  width: 50%;
}
.oryxCol {
  border: 1px solid black;
}
