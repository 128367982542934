.SingleLegSquatsAnalysisReportTab_Avatar {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 75% !important;
}

.QualityLabel {
  background-color: green;
  border-radius: 10px;
  padding: 2px 10px;
  color: #ffffff;
  margin-left: 10px;
}
.warning {
  background-color: orange !important;
}
.danger {
  background-color: red;
  color: #ffffff;
}
