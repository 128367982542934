.reportAvatar {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* .mobilityReportCard_Row {
  height: 75%;
} */

.mobilityReportCard_Title {
  display: block;
  text-align: center;
  font-size: 16px;
  color: var(--ion-color-primary);
  margin-top: 10px;
  font-weight: bold;
  margin-bottom: 10px;
}
.speed {
  font-weight: bold;
}
.SpeedLabel {
  background-color: green;
  border-radius: 10px;
  padding: 2px 10px;
  color: #ffffff;
  margin-left: 10px;
  font-size: 14px;
}
.warning {
  background-color: orange !important;
}
.danger {
  background-color: red;
  color: #ffffff;
}

.mobilityReportCard_Content {
  height: 350px;
}

.reportCard_Title {
  font-size: 17px;
  width: 75%;
  margin-right: 0px !important;
}
.reportCard_Subtitle {
  font-size: 10px;
  width: 75%;
  margin-right: 0px !important;
}

.reportInfo_Modal .modal-wrapper {
  background-color: white;
  border-radius: 4px;
  width: 1000px;
  height: 650px;
  top: 5%;
  left: 5%;
  right: 5%;
  bottom: 5%;
}

ion-modal {
  --ion-background-color: #ffffff;
}
.reportCard_Content p {
  margin-bottom: 1em;
}

.reportCard_Content p:last-child {
  margin-bottom: 0;
}

.reportCard_Small_Buttons {
  float: right;
  color: black;
  height: 35px;
  position: relative;
  top: -36px;
}

.reportCard_Infobutton {
  font-size: 22px;
}

.reportCard_Small {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0 !important;
}
.reportCard_Small_Error {
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0 !important;
  border: 1px solid var(--ion-color-danger);
}

.reportCard_Header {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  height: 70px;
}

.full_width {
  width: 100%;
}
