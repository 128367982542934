ion-label > div {
  padding-left: 30px;
  text-align: left;
  font-family: inherit;
  color: #737373;
  font-size: 18px;
  font-weight: normal;
  line-height: 1;
}
ion-col > div {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.icon {
  color: white;
}

.succes {
  color: var(--ion-color-success);
}
.must-item {
  padding-left: 40px;
}
